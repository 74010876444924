import { logger } from '@getjust/browser-logger';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback } from 'react';

import type { InboundFormMessageAtom } from '$business/atoms/message';

import { formMessageAtom } from '$business/atoms/message';
import { ZInboundFormMessage } from '$models/state';

export function useFormMessageAtom() {
  const [formMessage, setFormMessage] = useAtom(formMessageAtom);

  const onFormMessageChange = useCallback(
    (message: Partial<InboundFormMessageAtom>) => {
      const safeMessage = ZInboundFormMessage.safeParse({
        ...(formMessage ?? {}),
        ...message,
      });

      if (safeMessage.success) {
        setFormMessage(safeMessage.data);
      } else {
        logger.error('Error while parsing form message change', {
          error: safeMessage.error,
          hook: useFormMessageAtom.name,
        });
      }
    },
    [formMessage, setFormMessage],
  );

  const removeCurrentUser = () => {
    const safeMessage = ZInboundFormMessage.safeParse({
      ...(formMessage ?? {}),
    });

    if (safeMessage.success) {
      setFormMessage({ ...safeMessage.data, currentUser: undefined });
    }
  };

  return { formMessage, onFormMessageChange, removeCurrentUser };
}

export function useROFormMessageAtom() {
  return useAtomValue(formMessageAtom);
}
