import { StackProps } from '@chakra-ui/react';
import { formatPrice } from '@getjust/commons';
import { Cart } from '@getjust/leaf';
import Currency from 'currency.js';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { UpsellProduct } from '@getjust/gateway';

import Coupon from '$components/SvgLogos/Coupon';
import { useAddDiscount, useDeleteDiscount, useDeleteLineItems } from '$hooks/mutations';
import { useCart } from '$hooks/queries';
import { DISABLE_QUANTITY, DISABLE_STOCK } from '$src/constants';
import { useIsDesktop } from '$src/hooks/client/useIsDesktop';
import { useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { useAddLineItems } from '$src/hooks/mutations/useAddLineItems';
import { useUpdateLineItems } from '$src/hooks/mutations/useUpdateLineItems';
import { useFeatureFlag } from '$src/hooks/queries/useFeatureFlag';
import { useUpsellProducts } from '$src/hooks/queries/useUpsellProducts';
import { useCorrectionsAtom } from '$src/hooks/state/useCorrectionsAtom';
import { useEstimatedShippingAtom } from '$src/hooks/state/useEstimatedShippingAtom';
import { useLoadingAtom } from '$src/hooks/state/useLoadingAtom';
import { captureMixpanelAnalytics, capturePosthogAnalytics } from '$src/utils';

interface CartDetailProps extends StackProps {
  isUpsellDisabled?: boolean;
  showTotal?: boolean;
}

const CartDetail = ({ isUpsellDisabled, showTotal = true, ...props }: CartDetailProps) => {
  const { onLoadingChange } = useLoadingAtom();
  const { mutateAsync: updateLineItems } = useUpdateLineItems();
  const { addDiscount, status } = useAddDiscount();
  const { mutateAsync: deleteDiscount } = useDeleteDiscount();
  const { t } = useTranslation('common');
  const { data: cart, status: useCartStatus } = useCart();
  const { data: quantityFlag } = useFeatureFlag(DISABLE_QUANTITY);
  const { data: stockDisabledFlag } = useFeatureFlag(DISABLE_STOCK);
  const { track } = useTrackAmplitude();
  const { corrections, initCorrections, clearCorrections } = useCorrectionsAtom();
  const { estimatedShipping } = useEstimatedShippingAtom();
  const { isMobile } = useIsDesktop();

  const onDiscountAddClick = useCallback(
    async (code: string) => {
      captureMixpanelAnalytics('discount_code_apply');
      capturePosthogAnalytics('discount_code_apply');
      await addDiscount({
        code: code.trim(),
      });
    },
    [addDiscount],
  );

  const getLeftQuantityTitle = useCallback(
    (quantityAvailable: number | undefined, isAvailable: boolean): string => {
      if (!quantityAvailable && !isAvailable) return t('errors.out_of_stock');
      if (!quantityAvailable && isAvailable) return '';
      if (quantityAvailable === 1) return t('commons.leftQuantity', { count: quantityAvailable });
      return t('commons.leftQuantity_plurials', { count: quantityAvailable });
    },
    [t],
  );

  const onDeleteDiscount = useCallback((code: string) => deleteDiscount({ code }), [deleteDiscount]);
  const onUpdateItem = useCallback(
    async ({
      newQuantity,
      id,
      productId,
      variantId,
      customizationId,
    }: {
      id: string;
      productId: string | undefined;
      variantId: string | undefined;
      newQuantity: number;
      customizationId: string | undefined;
    }) => {
      const res = await updateLineItems({
        lineItems: [
          {
            id,
            productId,
            variantId,
            quantity: newQuantity,
            customizationId,
          },
        ],
      });
      if (!res.data.corrections?.length) {
        clearCorrections();
      } else {
        initCorrections(res.data.corrections);
      }
    },
    [updateLineItems, clearCorrections, initCorrections],
  );

  // Upsell
  const { data: upsellProducts, status: useUpsellProductsStatus } = useUpsellProducts(isUpsellDisabled);
  const { mutateAsync: addLineItems } = useAddLineItems();
  const [filteredUpsellProducts, setFilteredUpsellProducts] = useState<UpsellProduct[]>([]);

  useEffect(() => {
    setFilteredUpsellProducts(upsellProducts ?? []);
  }, [upsellProducts]);

  useEffect(() => {
    if (!isUpsellDisabled) {
      track('Cross sell opened');
    }
    if (cart?.corrections?.length) {
      initCorrections(cart?.corrections);
    }
    return () => {
      clearCorrections();
    };
  }, []);

  const onUpsellClick = useCallback(
    async (productToAdd: UpsellProduct) => {
      await addLineItems({
        lineItems: [
          {
            productId: productToAdd.productId,
            variantId: productToAdd.variantId,
            quantity: 1,
          },
        ],
      });

      track('Cross sell product added');

      const index = filteredUpsellProducts?.findIndex(
        (e: UpsellProduct) =>
          e.variantId === productToAdd.variantId ||
          !!e.variants.find((v: string) => v === productToAdd.variantId),
      );

      if (index !== -1) {
        const copy = [...filteredUpsellProducts];
        copy.splice(index, 1);
        setFilteredUpsellProducts(copy);
      }
    },
    [addLineItems, filteredUpsellProducts, track],
  );

  // Products
  const { mutateAsync: deleteLineItems } = useDeleteLineItems();
  const onDeleteProduct = useCallback(
    async ({ id }: { id: string }) => {
      await deleteLineItems({ lineItemIds: [id] });
    },
    [deleteLineItems],
  );
  const shippingMessage = useMemo(() => {
    if (
      cart?.shipping?.selected &&
      cart?.shipping?.address &&
      cart?.shipping?.address?.firstName !== 'Just' && // JUST Users in Prestashop
      cart?.shipping?.address?.lastName !== 'Anonymous' && // mandatory to have estimated
      cart?.shipping?.address?.address1 !== '142 AVENUE LEDRU ROLLIN' // shipping in prestashop
    ) {
      return cart?.totalShipping === 0
        ? t('notAuthed.freeShipping')
        : `${formatPrice(cart?.totalShipping, {
            currency: cart?.currency ? cart.currency : 'EUR',
          })}`;
    }
    if (estimatedShipping) {
      return estimatedShipping.amount === 0
        ? t('notAuthed.freeShipping')
        : estimatedShipping.currency === 'EUR'
          ? `${t('notAuthed.estimated', {
              amount: formatPrice(estimatedShipping.amount, {
                currency: 'EUR',
              }),
            })}`
          : `${t('notAuthed.estimated', {
              amount: formatPrice(estimatedShipping.amount, {
                currency: cart?.currency ?? 'EUR',
              }),
            })}`;
    }

    return t('commons.calculating');
  }, [cart, t, estimatedShipping]);

  const totalMessage = useMemo(() => {
    if (estimatedShipping && !cart?.shipping?.address) {
      return {
        label: t('commons.total'),
        value: formatPrice(Currency(cart?.totalAmount ?? 0).add(estimatedShipping.amount).value, {
          currency: cart?.currency ?? 'EUR',
        }),
      };
    }
    return {
      label: t('commons.total'),
      value: formatPrice(cart?.totalAmount ?? 0, { currency: cart?.currency ?? 'EUR' }),
    };
  }, [cart, t, estimatedShipping]);

  // show loader while pending requests
  useEffect(() => {
    onLoadingChange(
      useCartStatus === 'pending' || (!isUpsellDisabled && useUpsellProductsStatus === 'pending'),
    );
  }, [useCartStatus, isUpsellDisabled, useUpsellProductsStatus, onLoadingChange]);

  return (
    <Cart
      onDiscountAddClick={onDiscountAddClick}
      onDiscountRemove={onDeleteDiscount}
      discountApplyLabel={t('buttons.add_discount')}
      discountInputLabel={t('commons.discount_code')}
      discountErrorLabel={status === 'error' ? (t('errors.commons.unknown_discount') as string) : undefined}
      total={showTotal ? totalMessage : { label: '', value: '' }}
      subTotal={{
        label: !cart?.taxIncluded ? t('commons.subtotalHT') : t('commons.subtotal'),
        value: formatPrice((cart?.subTotal ?? 0) + (cart?.totalSale ?? 0), {
          currency: cart?.currency ?? 'EUR',
        }),
      }}
      shipping={{
        label: t('commons.shipping_method'),
        value: shippingMessage,
      }}
      tax={
        !cart?.taxIncluded
          ? {
              value: formatPrice(cart?.totalTax ?? 0, {
                currency: cart?.currency ?? 'EUR',
              }),
              label: t('commons.tax'),
            }
          : undefined
      }
      products={(cart?.lineItems ?? []).map((lineItem) => ({
        ...lineItem,
        price: formatPrice(lineItem?.totalAmount, { currency: cart?.currency ?? 'EUR' }),
        variant: lineItem?.variants,
        image: lineItem?.imageUrl ?? '',
      }))}
      totalSale={
        cart?.totalSale
          ? {
              label: t('commons.sale'),
              value: formatPrice(cart?.totalSale ?? 0, {
                currency: cart?.currency ?? 'EUR',
              }),
            }
          : undefined
      }
      totalDiscount={
        cart?.totalDiscount
          ? {
              label: t('commons.discount'),
              value: formatPrice(cart?.totalDiscount ?? 0, {
                currency: cart?.currency ?? 'EUR',
              }),
            }
          : undefined
      }
      discounts={cart?.discounts ?? []}
      upsellProducts={filteredUpsellProducts}
      onUpsellClick={onUpsellClick}
      upsellLabel={t('upsell.title') as string}
      onDeleteProduct={onDeleteProduct}
      deleteLabel={t('buttons.delete') as string}
      isUpsellDisabled={isUpsellDisabled}
      isQuantityDisabled={quantityFlag?.isActive}
      isStockDisabled={Boolean(stockDisabledFlag?.isActive)}
      currency={cart?.currency ?? 'EUR'}
      shouldFocusDiscountField={false}
      updateQuantity={onUpdateItem}
      taxIncludedTitle={t('commons.taxIncludedTitle') as string}
      leftQuantityTitles={
        cart?.lineItems?.map((lineItem) =>
          getLeftQuantityTitle(lineItem.quantityAvailable, lineItem.isAvailable),
        ) ?? ['']
      }
      corrections={corrections ?? []}
      Icon={Coupon}
      availabilityMessage={corrections?.length ? t('errors.availability_warning') : ''}
      updatedCartInformation={corrections?.length ? t('info.cart_updated') : ''}
      outOfStockMessage={corrections?.length ? t('errors.out_of_stock') : ''}
      isMobile={isMobile}
      {...props}
    />
  );
};

export default CartDetail;
