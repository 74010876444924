import { Box, BoxProps, Image, Text } from '@chakra-ui/react';
import { useCallback, useState } from 'react';

import { useIsDesktop } from '$src/hooks/client/useIsDesktop';
import { usePostMessage } from '$src/hooks/client/usePostMessage';
import { useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { useShop } from '$src/hooks/queries';
import { captureMixpanelAnalytics, capturePosthogAnalytics } from '$src/utils';

export const Header = (props: BoxProps) => {
  const { track } = useTrackAmplitude();
  const { postMessage } = usePostMessage();
  const { data: shop } = useShop();
  const urlLogoShop = `https://${process.env.NEXT_PUBLIC_LOGOS_BUCKET}.s3.eu-west-3.amazonaws.com/${shop?.logo}`;
  const [logoLoadFailed, setLogoLoadedFailed] = useState(false);
  const { isMobile } = useIsDesktop();

  const onBack = useCallback(() => {
    capturePosthogAnalytics('LEAVE');
    captureMixpanelAnalytics('LEAVE');
    track('Checkout form closed');
    return postMessage({
      emitter: 'JUST_FORM',
      action: 'CLOSE',
    });
  }, [postMessage, track]);

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      {...props}
      display="flex"
      h={isMobile ? '3rem' : '3.75rem'}
      mb="2px"
    >
      {!logoLoadFailed && shop?.logo ? (
        <Image
          alt="logo shop"
          textAlign="center"
          src={urlLogoShop}
          onError={() => {
            setLogoLoadedFailed(true);
          }}
          h="2.5rem"
          maxW="10rem"
        />
      ) : (
        <Text textAlign="center" py="4">
          {shop?.name ?? ''}
        </Text>
      )}
      <Box
        w="24px"
        h="24px"
        onClick={onBack}
        position="absolute"
        top="0"
        left="0"
        ml={isMobile ? '4' : '10'}
        pt={isMobile ? '3' : '5'}
        cursor="pointer"
        _hover={{
          bg: 'none',
        }}
      >
        <Image src="/images/backArrow.svg" aria-label="Close" />
      </Box>
    </Box>
  );
};
