import { useEffect } from 'react';

import { useMessageListenner } from '$src/hooks/client';
import { addIdentify } from '$src/hooks/client/useTrackAmplitude';
import { usePrepareCart } from '$src/hooks/mutations';
import { useEstimatedShipping } from '$src/hooks/mutations/useEstimatedShipping';
import { useCart, useUserLocationDetection } from '$src/hooks/queries';
import { useCartIdAtom, useCountryCodeAtom, useROFormMessageAtom } from '$src/hooks/state';
import { setXLanguageGatewayHeader, setXSessionHeaders } from '$src/http';

interface InitWrapperProps {
  children: any;
}

export const InitWrapper = ({ children }: InitWrapperProps) => {
  const message = useROFormMessageAtom();
  const { mutateAsync: getEstimatedShipping } = useEstimatedShipping();

  useEffect(() => {
    addIdentify('language', message?.language ?? '-');
  }, [message?.language]);

  useMessageListenner();
  useEffect(() => {
    setXSessionHeaders();
  }, []);
  const { data: location } = useUserLocationDetection();
  const { onCountryCodeChange, country } = useCountryCodeAtom();

  useEffect(() => {
    onCountryCodeChange(location?.location.country.code);
  }, [location, onCountryCodeChange]);

  useEffect(() => {
    setXLanguageGatewayHeader(message?.language ?? 'fr');
  }, [message?.language]);

  const { cartId } = useCartIdAtom();
  const { data: cart, refetch } = useCart();

  const { mutateAsync: prepareCart, status: prepareCartStatus } = usePrepareCart();

  useEffect(() => {
    if (
      !cartId &&
      message?.cart &&
      prepareCartStatus !== 'error' &&
      prepareCartStatus !== 'pending' &&
      country
    ) {
      prepareCart({
        cart: {
          ...message.cart,
          customerId: message.currentUser?.id,
          shippingAddress: message.currentUser?.address
            ? {
                firstName: message.currentUser.firstName ?? '',
                lastName: message.currentUser.lastName ?? '',
                address1: message.currentUser.address.address1 ?? '',
                city: message.currentUser.address.city ?? '',
                zip: message.currentUser.address.zip ?? '',
                country: message.currentUser.address.country ?? '',
                id: message.currentUser.address.id,
                phone: message.currentUser.phone,
                address2: message.currentUser.address.address2,
                province: message.currentUser.address.province,
                provinceCode: message.currentUser.address.province,
                countryCode: message.currentUser.address.countryCode ?? '',
              }
            : undefined,
        },
      });
    }
  }, [cartId, country, message, prepareCart, prepareCartStatus, location]);

  useEffect(() => {
    if (cart?.shipping?.ready === false) {
      refetch();
    }
  }, [cart, refetch]);

  useEffect(() => {
    if (
      message?.cart.lineItems &&
      message?.cart.lineItems.length !== 0 &&
      prepareCartStatus !== 'error' &&
      prepareCartStatus !== 'pending' &&
      location?.location &&
      location?.location?.latitude !== 0
    ) {
      getEstimatedShipping({
        cart: message.cart,
        longitude: location.location.longitude,
        latitude: location.location.latitude,
      });
    }
  }, [message?.cart.lineItems, location, getEstimatedShipping, prepareCartStatus]);

  return <>{children}</>;
};
