var L = {
  log: "log",
  debug: "debug",
  info: "info",
  warn: "warn",
  error: "error"
}, I = console, D = {};
Object.keys(L).forEach(function(e) {
  D[e] = I[e];
});
var ue = "Datadog Browser SDK:", E = {
  debug: D.debug.bind(I, ue),
  log: D.log.bind(I, ue),
  info: D.info.bind(I, ue),
  warn: D.warn.bind(I, ue),
  error: D.error.bind(I, ue)
};
function kt(e, t) {
  return function() {
    for (var n = [], r = 0; r < arguments.length; r++)
      n[r] = arguments[r];
    try {
      return e.apply(void 0, n);
    } catch (o) {
      E.error(t, o);
    }
  };
}
var ge;
(function(e) {
  e.PAGEHIDE = "pagehide", e.FEATURE_FLAGS = "feature_flags", e.RESOURCE_PAGE_STATES = "resource_page_states", e.COLLECT_FLUSH_REASON = "collect_flush_reason", e.SCROLLMAP = "scrollmap", e.DISABLE_REPLAY_INLINE_CSS = "disable_replay_inline_css";
})(ge || (ge = {}));
var tt = /* @__PURE__ */ new Set();
function yn(e) {
  e.forEach(function(t) {
    tt.add(t);
  });
}
function Ut(e) {
  return tt.has(e);
}
function Sn() {
  return tt;
}
function Je(e) {
  return e !== 0 && Math.random() * 100 <= e;
}
function Fe(e) {
  return En(e) && e >= 0 && e <= 100;
}
function En(e) {
  return typeof e == "number";
}
var he = 1e3, K = 60 * he, _n = 60 * K;
function le() {
  return (/* @__PURE__ */ new Date()).getTime();
}
function B() {
  return le();
}
function Te() {
  return performance.now();
}
function re() {
  return { relative: Te(), timeStamp: B() };
}
function Cn() {
  return { relative: 0, timeStamp: Nt() };
}
function On(e, t) {
  return t - e;
}
function wn(e, t) {
  return e + t;
}
function Ln(e) {
  return e - Nt();
}
var Ge;
function Nt() {
  return Ge === void 0 && (Ge = performance.timing.navigationStart), Ge;
}
var P = 1024, Pt = 1024 * P, Rn = /[^\u0000-\u007F]/;
function Pe(e) {
  return Rn.test(e) ? window.TextEncoder !== void 0 ? new TextEncoder().encode(e).length : new Blob([e]).size : e.length;
}
function oe(e, t) {
  return e.indexOf(t) !== -1;
}
function Bt(e) {
  if (Array.from)
    return Array.from(e);
  var t = [];
  if (e instanceof Set)
    e.forEach(function(r) {
      return t.push(r);
    });
  else
    for (var n = 0; n < e.length; n++)
      t.push(e[n]);
  return t;
}
function Tn(e, t) {
  for (var n = 0; n < e.length; n += 1) {
    var r = e[n];
    if (t(r, n))
      return r;
  }
}
function ee(e) {
  return Object.keys(e).map(function(t) {
    return e[t];
  });
}
function xn(e) {
  return Object.keys(e).map(function(t) {
    return [t, e[t]];
  });
}
function Mt(e, t) {
  return e.slice(0, t.length) === t;
}
function An(e, t) {
  return e.slice(-t.length) === t;
}
function O(e) {
  for (var t = [], n = 1; n < arguments.length; n++)
    t[n - 1] = arguments[n];
  return t.forEach(function(r) {
    for (var o in r)
      Object.prototype.hasOwnProperty.call(r, o) && (e[o] = r[o]);
  }), e;
}
function In(e) {
  return O({}, e);
}
function kn(e, t) {
  return Object.keys(e).some(function(n) {
    return e[n] === t;
  });
}
function Ft(e) {
  return Object.keys(e).length === 0;
}
function M() {
  if (typeof globalThis == "object")
    return globalThis;
  Object.defineProperty(Object.prototype, "_dd_temp_", {
    get: function() {
      return this;
    },
    configurable: !0
  });
  var e = _dd_temp_;
  return delete Object.prototype._dd_temp_, typeof e != "object" && (typeof self == "object" ? e = self : typeof window == "object" ? e = window : e = {}), e;
}
function te(e, t) {
  var n = M(), r;
  return n.Zone && typeof n.Zone.__symbol__ == "function" && (r = e[n.Zone.__symbol__(t)]), r || (r = e[t]), r;
}
var Un = function(e, t, n) {
  if (n || arguments.length === 2)
    for (var r = 0, o = t.length, i; r < o; r++)
      (i || !(r in t)) && (i || (i = Array.prototype.slice.call(t, 0, r)), i[r] = t[r]);
  return e.concat(i || Array.prototype.slice.call(t));
}, xe, Gt = !1;
function Nn(e) {
  xe = e;
}
function Pn(e) {
  Gt = e;
}
function Bn(e, t, n) {
  var r = n.value;
  n.value = function() {
    for (var o = [], i = 0; i < arguments.length; i++)
      o[i] = arguments[i];
    var a = xe ? b(r) : r;
    return a.apply(this, o);
  };
}
function b(e) {
  return function() {
    return z(e, this, arguments);
  };
}
function z(e, t, n) {
  try {
    return e.apply(t, n);
  } catch (r) {
    if (Xe(r), xe)
      try {
        xe(r);
      } catch (o) {
        Xe(o);
      }
  }
}
function Xe() {
  for (var e = [], t = 0; t < arguments.length; t++)
    e[t] = arguments[t];
  Gt && E.error.apply(E, Un(["[MONITOR]"], e, !1));
}
function be(e, t) {
  return te(M(), "setTimeout")(b(e), t);
}
function Dt(e) {
  te(M(), "clearTimeout")(e);
}
function nt(e, t) {
  return te(M(), "setInterval")(b(e), t);
}
function Ht(e) {
  te(M(), "clearInterval")(e);
}
var C = (
  /** @class */
  function() {
    function e(t) {
      this.onFirstSubscribe = t, this.observers = [];
    }
    return e.prototype.subscribe = function(t) {
      var n = this;
      return !this.observers.length && this.onFirstSubscribe && (this.onLastUnsubscribe = this.onFirstSubscribe() || void 0), this.observers.push(t), {
        unsubscribe: function() {
          n.observers = n.observers.filter(function(r) {
            return t !== r;
          }), !n.observers.length && n.onLastUnsubscribe && n.onLastUnsubscribe();
        }
      };
    }, e.prototype.notify = function(t) {
      this.observers.forEach(function(n) {
        return n(t);
      });
    }, e;
  }()
);
function jt() {
  for (var e = [], t = 0; t < arguments.length; t++)
    e[t] = arguments[t];
  var n = new C(function() {
    var r = e.map(function(o) {
      return o.subscribe(function(i) {
        return n.notify(i);
      });
    });
    return function() {
      return r.forEach(function(o) {
        return o.unsubscribe();
      });
    };
  });
  return n;
}
function Yt(e, t, n) {
  var r = n && n.leading !== void 0 ? n.leading : !0, o = n && n.trailing !== void 0 ? n.trailing : !0, i = !1, a, s;
  return {
    throttled: function() {
      for (var c = [], u = 0; u < arguments.length; u++)
        c[u] = arguments[u];
      if (i) {
        a = c;
        return;
      }
      r ? e.apply(void 0, c) : a = c, i = !0, s = be(function() {
        o && a && e.apply(void 0, a), i = !1, a = void 0;
      }, t);
    },
    cancel: function() {
      Dt(s), i = !1, a = void 0;
    }
  };
}
function me() {
}
function W(e) {
  return e ? (
    // eslint-disable-next-line  no-bitwise
    (parseInt(e, 10) ^ Math.random() * 16 >> parseInt(e, 10) / 4).toString(16)
  ) : "".concat(1e7, "-").concat(1e3, "-").concat(4e3, "-").concat(8e3, "-").concat(1e11).replace(/[018]/g, W);
}
var Ae = /([\w-]+)\s*=\s*([^;]+)/g;
function Mn(e, t) {
  for (Ae.lastIndex = 0; ; ) {
    var n = Ae.exec(e);
    if (n) {
      if (n[1] === t)
        return n[2];
    } else
      break;
  }
}
function Fn(e) {
  var t = /* @__PURE__ */ new Map();
  for (Ae.lastIndex = 0; ; ) {
    var n = Ae.exec(e);
    if (n)
      t.set(n[1], n[2]);
    else
      break;
  }
  return t;
}
function Gn(e, t, n) {
  n === void 0 && (n = "");
  var r = e.charCodeAt(t - 1), o = r >= 55296 && r <= 56319, i = o ? t + 1 : t;
  return e.length <= i ? e : "".concat(e.slice(0, i)).concat(n);
}
var zt = 4 * _n, qt = 15 * K;
function Dn() {
  return Hn() === 1;
}
var Ee;
function Hn() {
  return Ee ?? (Ee = jn());
}
function jn(e) {
  var t;
  e === void 0 && (e = window);
  var n = e.navigator.userAgent;
  return e.chrome || /HeadlessChrome/.test(n) ? 1 : (
    // navigator.vendor is deprecated, but it is the most resilient way we found to detect
    // "Apple maintained browsers" (AKA Safari). If one day it gets removed, we still have the
    // useragent test as a semi-working fallback.
    ((t = e.navigator.vendor) === null || t === void 0 ? void 0 : t.indexOf("Apple")) === 0 || /safari/i.test(n) && !/chrome|android/i.test(n) ? 2 : e.document.documentMode ? 0 : 3
  );
}
function Be(e, t, n, r) {
  var o = /* @__PURE__ */ new Date();
  o.setTime(o.getTime() + n);
  var i = "expires=".concat(o.toUTCString()), a = r && r.crossSite ? "none" : "strict", s = r && r.domain ? ";domain=".concat(r.domain) : "", c = r && r.secure ? ";secure" : "";
  document.cookie = "".concat(e, "=").concat(t, ";").concat(i, ";path=/;samesite=").concat(a).concat(s).concat(c);
}
function rt(e) {
  return Mn(document.cookie, e);
}
var De;
function Y(e) {
  return De || (De = Fn(document.cookie)), De.get(e);
}
function ot(e, t) {
  Be(e, "", 0, t);
}
function Yn(e) {
  if (document.cookie === void 0 || document.cookie === null)
    return !1;
  try {
    var t = "dd_cookie_test_".concat(W()), n = "test";
    Be(t, n, K, e);
    var r = rt(t) === n;
    return ot(t, e), r;
  } catch (o) {
    return E.error(o), !1;
  }
}
var He;
function zn() {
  if (He === void 0) {
    for (var e = "dd_site_test_".concat(W()), t = "test", n = window.location.hostname.split("."), r = n.pop(); n.length && !rt(e); )
      r = "".concat(n.pop(), ".").concat(r), Be(e, t, he, { domain: r });
    ot(e, { domain: r }), He = r;
  }
  return He;
}
var J = "_dd_s", Kt = /^([a-z]+)=([a-z0-9-]+)$/, it = "&";
function $e(e) {
  return Ft(e);
}
function Wt(e) {
  e.expire = String(le() + qt);
}
function Jt(e) {
  return xn(e).map(function(t) {
    var n = t[0], r = t[1];
    return "".concat(n, "=").concat(r);
  }).join(it);
}
function Xt(e) {
  var t = {};
  return qn(e) && e.split(it).forEach(function(n) {
    var r = Kt.exec(n);
    if (r !== null) {
      var o = r[1], i = r[2];
      t[o] = i;
    }
  }), t;
}
function qn(e) {
  return !!e && (e.indexOf(it) !== -1 || Kt.test(e));
}
var Kn = "_dd", Wn = "_dd_r", Jn = "_dd_l", Xn = "rum", $n = "logs";
function Zn(e) {
  var t = Y(J);
  if (!t) {
    var n = Y(Kn), r = Y(Wn), o = Y(Jn), i = {};
    n && (i.id = n), o && /^[01]$/.test(o) && (i[$n] = o), r && /^[012]$/.test(r) && (i[Xn] = r), $e(i) || (Wt(i), e.persistSession(i));
  }
}
function Vn(e) {
  var t = rr(e);
  return Yn(t) ? { type: "Cookie", cookieOptions: t } : void 0;
}
function Qn(e) {
  var t = {
    /**
     * Lock strategy allows mitigating issues due to concurrent access to cookie.
     * This issue concerns only chromium browsers and enabling this on firefox increases cookie write failures.
     */
    isLockEnabled: Dn(),
    persistSession: er(e),
    retrieveSession: tr,
    clearSession: nr(e)
  };
  return Zn(t), t;
}
function er(e) {
  return function(t) {
    Be(J, Jt(t), qt, e);
  };
}
function tr() {
  var e = rt(J);
  return Xt(e);
}
function nr(e) {
  return function() {
    ot(J, e);
  };
}
function rr(e) {
  var t = {};
  return t.secure = !!e.useSecureSessionCookie || !!e.useCrossSiteSessionCookie, t.crossSite = !!e.useCrossSiteSessionCookie, e.trackSessionAcrossSubdomains && (t.domain = zn()), t;
}
var or = "_dd_test_";
function ir() {
  try {
    var e = W(), t = "".concat(or).concat(e);
    localStorage.setItem(t, e);
    var n = localStorage.getItem(t);
    return localStorage.removeItem(t), e === n ? { type: "LocalStorage" } : void 0;
  } catch {
    return;
  }
}
function ar() {
  return {
    isLockEnabled: !1,
    persistSession: sr,
    retrieveSession: ur,
    clearSession: cr
  };
}
function sr(e) {
  localStorage.setItem(J, Jt(e));
}
function ur() {
  var e = localStorage.getItem(J);
  return Xt(e);
}
function cr() {
  localStorage.removeItem(J);
}
var fr = 10, lr = 100, $t = [], Le;
function de(e, t, n) {
  var r;
  n === void 0 && (n = 0);
  var o = t.isLockEnabled, i = t.retrieveSession, a = t.persistSession, s = t.clearSession;
  if (Le || (Le = e), e !== Le) {
    $t.push(e);
    return;
  }
  if (o && n >= lr) {
    gt(t);
    return;
  }
  var c, u = i();
  if (o) {
    if (u.lock) {
      _e(e, t, n);
      return;
    }
    if (c = W(), u.lock = c, a(u), u = i(), u.lock !== c) {
      _e(e, t, n);
      return;
    }
  }
  var f = e.process(u);
  if (o && (u = i(), u.lock !== c)) {
    _e(e, t, n);
    return;
  }
  if (f && ($e(f) ? s() : (Wt(f), a(f))), o && !(f && $e(f))) {
    if (u = i(), u.lock !== c) {
      _e(e, t, n);
      return;
    }
    delete u.lock, a(u), f = u;
  }
  (r = e.after) === null || r === void 0 || r.call(e, f || u), gt(t);
}
function _e(e, t, n) {
  be(function() {
    de(e, t, n + 1);
  }, fr);
}
function gt(e) {
  Le = void 0;
  var t = $t.shift();
  t && de(t, e);
}
var ht = he;
function dr(e) {
  var t = Vn(e);
  return !t && e.allowFallbackToLocalStorage && (t = ir()), t;
}
function vr(e, t, n) {
  var r = new C(), o = new C(), i = e.type === "Cookie" ? Qn(e.cookieOptions) : ar(), a = i.clearSession, s = i.retrieveSession, c = nt(h, ht), u = se();
  function f() {
    var g;
    de({
      process: function(F) {
        var Se = l(F);
        return g = v(Se), Se;
      },
      after: function(F) {
        g && !p() && T(F), u = F;
      }
    }, i);
  }
  function d() {
    de({
      process: function(g) {
        return p() ? l(g) : void 0;
      }
    }, i);
  }
  function h() {
    de({
      process: function(g) {
        return Me(g) ? void 0 : {};
      },
      after: l
    }, i);
  }
  function l(g) {
    return Me(g) || (g = {}), p() && (m(g) ? _() : u = g), g;
  }
  function v(g) {
    var F = n(g[t]), Se = F.trackingType, pt = F.isTracked;
    return g[t] = Se, pt && !g.id && (g.id = W(), g.created = String(le())), pt;
  }
  function p() {
    return u[t] !== void 0;
  }
  function m(g) {
    return u.id !== g.id || u[t] !== g[t];
  }
  function _() {
    u = {}, o.notify();
  }
  function T(g) {
    u = g, r.notify();
  }
  function se() {
    var g = s();
    return Me(g) ? g : {};
  }
  function Me(g) {
    return (g.created === void 0 || le() - Number(g.created) < zt) && (g.expire === void 0 || le() < Number(g.expire));
  }
  return {
    expandOrRenewSession: Yt(f, ht).throttled,
    expandSession: d,
    getSession: function() {
      return u;
    },
    renewObservable: r,
    expireObservable: o,
    expire: function() {
      a(), l({});
    },
    stop: function() {
      Ht(c);
    }
  };
}
function ie(e, t, n) {
  if (typeof e != "object" || e === null)
    return JSON.stringify(e);
  var r = V(Object.prototype), o = V(Array.prototype), i = V(Object.getPrototypeOf(e)), a = V(e);
  try {
    return JSON.stringify(e, t, n);
  } catch {
    return "<error: unable to serialize object>";
  } finally {
    r(), o(), i(), a();
  }
}
function V(e) {
  var t = e, n = t.toJSON;
  return n ? (delete t.toJSON, function() {
    t.toJSON = n;
  }) : me;
}
function at(e) {
  return pr(e, hr()).href;
}
function pr(e, t) {
  var n = gr();
  if (n)
    try {
      return t !== void 0 ? new n(e, t) : new n(e);
    } catch (a) {
      throw new Error("Failed to construct URL: ".concat(String(a), " ").concat(ie({ url: e, base: t })));
    }
  if (t === void 0 && !/:/.test(e))
    throw new Error("Invalid URL: '".concat(e, "'"));
  var r = document, o = r.createElement("a");
  if (t !== void 0) {
    r = document.implementation.createHTMLDocument("");
    var i = r.createElement("base");
    i.href = t, r.head.appendChild(i), r.body.appendChild(o);
  }
  return o.href = e, o;
}
var bt = URL, Ce;
function gr() {
  if (Ce === void 0)
    try {
      var e = new bt("http://test/path");
      Ce = e.href === "http://test/path";
    } catch {
      Ce = !1;
    }
  return Ce ? bt : void 0;
}
function hr() {
  return br(window.location);
}
function br(e) {
  if (e.origin && e.origin !== "null")
    return e.origin;
  var t = e.host.replace(/(:80|:443)$/, "");
  return "".concat(e.protocol, "//").concat(t);
}
var mr = "datad0g.com", ve = "datadoghq.com", yr = "ddog-gov.com";
function pe(e, t, n) {
  var r = Sr(e, t);
  return {
    build: function(o, i) {
      var a = _r(e, t, n, o, i);
      return r(a);
    },
    urlPrefix: r(""),
    trackType: t
  };
}
function Sr(e, t) {
  var n = "/api/v2/".concat(t), r = e.proxy;
  if (typeof r == "string") {
    var o = at(r);
    return function(a) {
      return "".concat(o, "?ddforward=").concat(encodeURIComponent("".concat(n, "?").concat(a)));
    };
  }
  if (typeof r == "function")
    return function(a) {
      return r({ path: n, parameters: a });
    };
  var i = Er(e);
  return function(a) {
    return "https://".concat(i).concat(n, "?").concat(a);
  };
}
function Er(e) {
  var t = e.site, n = t === void 0 ? ve : t, r = e.internalAnalyticsSubdomain;
  if (r && n === ve)
    return "".concat(r, ".").concat(ve);
  var o = n.split("."), i = o.pop();
  return "browser-intake-".concat(o.join("-"), ".").concat(i);
}
function _r(e, t, n, r, o) {
  var i = e.clientToken, a = e.internalAnalyticsSubdomain, s = o.retry, c = o.flushReason, u = o.encoding, f = ["sdk_version:".concat("5.4.0"), "api:".concat(r)].concat(n);
  c && Ut(ge.COLLECT_FLUSH_REASON) && f.push("flush_reason:".concat(c)), s && f.push("retry_count:".concat(s.count), "retry_after:".concat(s.lastFailureStatus));
  var d = [
    "ddsource=browser",
    "ddtags=".concat(encodeURIComponent(f.join(","))),
    "dd-api-key=".concat(i),
    "dd-evp-origin-version=".concat(encodeURIComponent("5.4.0")),
    "dd-evp-origin=browser",
    "dd-request-id=".concat(W())
  ];
  return u && d.push("dd-evp-encoding=".concat(u)), t === "rum" && d.push("batch_time=".concat(B())), a && d.reverse(), d.join("&");
}
var Cr = 200;
function Or(e) {
  var t = e.env, n = e.service, r = e.version, o = e.datacenter, i = [];
  return t && i.push(Oe("env", t)), n && i.push(Oe("service", n)), r && i.push(Oe("version", r)), o && i.push(Oe("datacenter", o)), i;
}
var wr = /[^a-z0-9_:./-]/;
function Oe(e, t) {
  var n = Cr - e.length - 1;
  (t.length > n || wr.test(t)) && E.warn("".concat(e, " value doesn't meet tag requirements and will be sanitized"));
  var r = t.replace(/,/g, "_");
  return "".concat(e, ":").concat(r);
}
function Lr(e) {
  var t = Or(e), n = Rr(e, t), r = ee(n).map(function(i) {
    return i.urlPrefix;
  }), o = Tr(e, r, t);
  return O({
    isIntakeUrl: function(i) {
      return r.some(function(a) {
        return i.indexOf(a) === 0;
      });
    },
    replica: o,
    site: e.site || ve
  }, n);
}
function Rr(e, t) {
  return {
    logsEndpointBuilder: pe(e, "logs", t),
    rumEndpointBuilder: pe(e, "rum", t),
    sessionReplayEndpointBuilder: pe(e, "replay", t)
  };
}
function Tr(e, t, n) {
  if (e.replica) {
    var r = O({}, e, {
      site: ve,
      clientToken: e.replica.clientToken
    }), o = {
      logsEndpointBuilder: pe(r, "logs", n),
      rumEndpointBuilder: pe(r, "rum", n)
    };
    return t.push.apply(t, ee(o).map(function(i) {
      return i.urlPrefix;
    })), O({ applicationId: e.replica.applicationId }, o);
  }
}
function xr(e) {
  var t, n, r;
  if (!e || !e.clientToken) {
    E.error("Client Token is not configured, we will not send any data.");
    return;
  }
  if (e.sessionSampleRate !== void 0 && !Fe(e.sessionSampleRate)) {
    E.error("Session Sample Rate should be a number between 0 and 100");
    return;
  }
  if (e.telemetrySampleRate !== void 0 && !Fe(e.telemetrySampleRate)) {
    E.error("Telemetry Sample Rate should be a number between 0 and 100");
    return;
  }
  if (e.telemetryConfigurationSampleRate !== void 0 && !Fe(e.telemetryConfigurationSampleRate)) {
    E.error("Telemetry Configuration Sample Rate should be a number between 0 and 100");
    return;
  }
  return Array.isArray(e.enableExperimentalFeatures) && yn(e.enableExperimentalFeatures.filter(function(o) {
    return kn(ge, o);
  })), O({
    beforeSend: e.beforeSend && kt(e.beforeSend, "beforeSend threw an error:"),
    sessionStoreStrategyType: dr(e),
    sessionSampleRate: (t = e.sessionSampleRate) !== null && t !== void 0 ? t : 100,
    telemetrySampleRate: (n = e.telemetrySampleRate) !== null && n !== void 0 ? n : 20,
    telemetryConfigurationSampleRate: (r = e.telemetryConfigurationSampleRate) !== null && r !== void 0 ? r : 5,
    service: e.service,
    silentMultipleInit: !!e.silentMultipleInit,
    allowUntrustedEvents: !!e.allowUntrustedEvents,
    /**
     * beacon payload max queue size implementation is 64kb
     * ensure that we leave room for logs, rum and potential other users
     */
    batchBytesLimit: 16 * P,
    eventRateLimiterThreshold: 3e3,
    maxTelemetryEventsPerPage: 15,
    /**
     * flush automatically, aim to be lower than ALB connection timeout
     * to maximize connection reuse.
     */
    flushTimeout: 30 * he,
    /**
     * Logs intake limit
     */
    batchMessagesLimit: 50,
    messageBytesLimit: 256 * P
  }, Lr(e));
}
function Ar(e) {
  return {
    session_sample_rate: e.sessionSampleRate,
    telemetry_sample_rate: e.telemetrySampleRate,
    telemetry_configuration_sample_rate: e.telemetryConfigurationSampleRate,
    use_before_send: !!e.beforeSend,
    use_cross_site_session_cookie: e.useCrossSiteSessionCookie,
    use_secure_session_cookie: e.useSecureSessionCookie,
    use_proxy: !!e.proxy,
    silent_multiple_init: e.silentMultipleInit,
    track_session_across_subdomains: e.trackSessionAcrossSubdomains,
    allow_fallback_to_local_storage: !!e.allowFallbackToLocalStorage,
    store_contexts_across_pages: !!e.storeContextsAcrossPages,
    allow_untrusted_events: !!e.allowUntrustedEvents
  };
}
function Zt(e, t, n) {
  var r = e[t], o = n(r), i = function() {
    if (typeof o == "function")
      return o.apply(this, arguments);
  };
  return e[t] = i, {
    stop: function() {
      e[t] === i ? e[t] = r : o = r;
    }
  };
}
function Q(e, t, n) {
  var r = n.before, o = n.after;
  return Zt(e, t, function(i) {
    return function() {
      var a = arguments, s;
      return r && z(r, this, a), typeof i == "function" && (s = i.apply(this, a)), o && z(o, this, a), s;
    };
  });
}
var ye = "?";
function U(e) {
  var t = [], n = je(e, "stack"), r = String(e);
  return n && Mt(n, r) && (n = n.slice(r.length)), n && n.split(`
`).forEach(function(o) {
    var i = Ur(o) || Pr(o) || Mr(o) || Dr(o);
    i && (!i.func && i.line && (i.func = ye), t.push(i));
  }), {
    message: je(e, "message"),
    name: je(e, "name"),
    stack: t
  };
}
var Vt = "((?:file|https?|blob|chrome-extension|native|eval|webpack|snippet|<anonymous>|\\w+\\.|\\/).*?)", ne = "(?::(\\d+))", Ir = new RegExp("^\\s*at (.*?) ?\\(".concat(Vt).concat(ne, "?").concat(ne, "?\\)?\\s*$"), "i"), kr = new RegExp("\\((\\S*)".concat(ne).concat(ne, "\\)"));
function Ur(e) {
  var t = Ir.exec(e);
  if (t) {
    var n = t[2] && t[2].indexOf("native") === 0, r = t[2] && t[2].indexOf("eval") === 0, o = kr.exec(t[2]);
    return r && o && (t[2] = o[1], t[3] = o[2], t[4] = o[3]), {
      args: n ? [t[2]] : [],
      column: t[4] ? +t[4] : void 0,
      func: t[1] || ye,
      line: t[3] ? +t[3] : void 0,
      url: n ? void 0 : t[2]
    };
  }
}
var Nr = new RegExp("^\\s*at ?".concat(Vt).concat(ne, "?").concat(ne, "??\\s*$"), "i");
function Pr(e) {
  var t = Nr.exec(e);
  if (t)
    return {
      args: [],
      column: t[3] ? +t[3] : void 0,
      func: ye,
      line: t[2] ? +t[2] : void 0,
      url: t[1]
    };
}
var Br = /^\s*at (?:((?:\[object object\])?.+) )?\(?((?:file|ms-appx|https?|webpack|blob):.*?):(\d+)(?::(\d+))?\)?\s*$/i;
function Mr(e) {
  var t = Br.exec(e);
  if (t)
    return {
      args: [],
      column: t[4] ? +t[4] : void 0,
      func: t[1] || ye,
      line: +t[3],
      url: t[2]
    };
}
var Fr = /^\s*(.*?)(?:\((.*?)\))?(?:^|@)((?:file|https?|blob|chrome|webpack|resource|capacitor|\[native).*?|[^@]*bundle)(?::(\d+))?(?::(\d+))?\s*$/i, Gr = /(\S+) line (\d+)(?: > eval line \d+)* > eval/i;
function Dr(e) {
  var t = Fr.exec(e);
  if (t) {
    var n = t[3] && t[3].indexOf(" > eval") > -1, r = Gr.exec(t[3]);
    return n && r && (t[3] = r[1], t[4] = r[2], t[5] = void 0), {
      args: t[2] ? t[2].split(",") : [],
      column: t[5] ? +t[5] : void 0,
      func: t[1] || ye,
      line: t[4] ? +t[4] : void 0,
      url: t[3]
    };
  }
}
function je(e, t) {
  if (!(typeof e != "object" || !e || !(t in e))) {
    var n = e[t];
    return typeof n == "string" ? n : void 0;
  }
}
function Hr(e, t, n, r) {
  var o = [{ url: t, column: r, line: n }], i = Yr(e), a = i.name, s = i.message;
  return {
    name: a,
    message: s,
    stack: o
  };
}
var jr = /^(?:[Uu]ncaught (?:exception: )?)?(?:((?:Eval|Internal|Range|Reference|Syntax|Type|URI|)Error): )?([\s\S]*)$/;
function Yr(e) {
  var t, n, r;
  return {}.toString.call(e) === "[object String]" && (t = jr.exec(e), n = t[1], r = t[2]), { name: n, message: r };
}
var zr = 220 * P, qr = "$", Kr = 3;
function k(e, t) {
  var n;
  t === void 0 && (t = zr);
  var r = V(Object.prototype), o = V(Array.prototype), i = [], a = /* @__PURE__ */ new WeakMap(), s = Ye(e, qr, void 0, i, a), c = ((n = JSON.stringify(s)) === null || n === void 0 ? void 0 : n.length) || 0;
  if (c > t) {
    ze(t, "discarded", e);
    return;
  }
  for (; i.length > 0 && c < t; ) {
    var u = i.shift(), f = 0;
    if (Array.isArray(u.source))
      for (var d = 0; d < u.source.length; d++) {
        var h = Ye(u.source[d], u.path, d, i, a);
        if (h !== void 0 ? c += JSON.stringify(h).length : c += 4, c += f, f = 1, c > t) {
          ze(t, "truncated", e);
          break;
        }
        u.target[d] = h;
      }
    else
      for (var d in u.source)
        if (Object.prototype.hasOwnProperty.call(u.source, d)) {
          var h = Ye(u.source[d], u.path, d, i, a);
          if (h !== void 0 && (c += JSON.stringify(h).length + f + d.length + Kr, f = 1), c > t) {
            ze(t, "truncated", e);
            break;
          }
          u.target[d] = h;
        }
  }
  return r(), o(), s;
}
function Ye(e, t, n, r, o) {
  var i = Xr(e);
  if (!i || typeof i != "object")
    return Wr(i);
  var a = Jr(i);
  if (a !== "[Object]" && a !== "[Array]" && a !== "[Error]")
    return a;
  var s = e;
  if (o.has(s))
    return "[Reference seen at ".concat(o.get(s), "]");
  var c = n !== void 0 ? "".concat(t, ".").concat(n) : t, u = Array.isArray(i) ? [] : {};
  return o.set(s, c), r.push({ source: i, target: u, path: c }), u;
}
function Wr(e) {
  return typeof e == "bigint" ? "[BigInt] ".concat(e.toString()) : typeof e == "function" ? "[Function] ".concat(e.name || "unknown") : typeof e == "symbol" ? "[Symbol] ".concat(e.description || e.toString()) : e;
}
function Jr(e) {
  try {
    if (e instanceof Event)
      return {
        isTrusted: e.isTrusted
      };
    var t = Object.prototype.toString.call(e), n = t.match(/\[object (.*)\]/);
    if (n && n[1])
      return "[".concat(n[1], "]");
  } catch {
  }
  return "[Unserializable]";
}
function Xr(e) {
  var t = e;
  if (t && typeof t.toJSON == "function")
    try {
      return t.toJSON();
    } catch {
    }
  return e;
}
function ze(e, t, n) {
  E.warn("The data provided has been ".concat(t, " as it is over the limit of ").concat(e, " characters:"), n);
}
var Qt = "No stack, consider using an instance of Error";
function en(e) {
  var t = e.stackTrace, n = e.originalError, r = e.handlingStack, o = e.startClocks, i = e.nonErrorPrefix, a = e.source, s = e.handling, c = n instanceof Error, u = $r(t, c, i, n), f = Zr(c, t) ? X(t) : Qt, d = c ? eo(n, a) : void 0, h = t == null ? void 0 : t.name, l = tn(n);
  return {
    startClocks: o,
    source: a,
    handling: s,
    handlingStack: r,
    originalError: n,
    type: h,
    message: u,
    stack: f,
    causes: d,
    fingerprint: l
  };
}
function $r(e, t, n, r) {
  return e != null && e.message && (e != null && e.name) ? e.message : t ? "Empty message" : "".concat(n, " ").concat(ie(k(r)));
}
function Zr(e, t) {
  return t === void 0 ? !1 : e ? !0 : t.stack.length > 0 && (t.stack.length > 1 || t.stack[0].url !== void 0);
}
function tn(e) {
  return e instanceof Error && "dd_fingerprint" in e ? String(e.dd_fingerprint) : void 0;
}
function X(e) {
  var t = nn(e);
  return e.stack.forEach(function(n) {
    var r = n.func === "?" ? "<anonymous>" : n.func, o = n.args && n.args.length > 0 ? "(".concat(n.args.join(", "), ")") : "", i = n.line ? ":".concat(n.line) : "", a = n.line && n.column ? ":".concat(n.column) : "";
    t += `
  at `.concat(r).concat(o, " @ ").concat(n.url).concat(i).concat(a);
  }), t;
}
function Vr(e) {
  var t;
  return (t = /@ (.+)/.exec(e)) === null || t === void 0 ? void 0 : t[1];
}
function nn(e) {
  return "".concat(e.name || "Error", ": ").concat(e.message);
}
function Qr() {
  var e = 2, t = new Error(), n;
  if (!t.stack)
    try {
      throw t;
    } catch {
    }
  return z(function() {
    var r = U(t);
    r.stack = r.stack.slice(e), n = X(r);
  }), n;
}
function eo(e, t) {
  for (var n = e, r = []; (n == null ? void 0 : n.cause) instanceof Error && r.length < 10; ) {
    var o = U(n.cause);
    r.push({
      message: n.cause.message,
      source: t,
      type: o == null ? void 0 : o.name,
      stack: o && X(o)
    }), n = n.cause;
  }
  return r.length ? r : void 0;
}
var R = {
  AGENT: "agent",
  CONSOLE: "console",
  CUSTOM: "custom",
  LOGGER: "logger",
  NETWORK: "network",
  SOURCE: "source",
  REPORT: "report"
};
function to(e) {
  var t = function(o, i) {
    var a = en({
      stackTrace: o,
      originalError: i,
      startClocks: re(),
      nonErrorPrefix: "Uncaught",
      source: R.SOURCE,
      handling: "unhandled"
    });
    e.notify(a);
  }, n = no(t).stop, r = ro(t).stop;
  return {
    stop: function() {
      n(), r();
    }
  };
}
function no(e) {
  return Q(window, "onerror", {
    before: function(t, n, r, o, i) {
      var a;
      i instanceof Error ? a = U(i) : a = Hr(t, n, r, o), e(a, i ?? t);
    }
  });
}
function ro(e) {
  return Q(window, "onunhandledrejection", {
    before: function(t) {
      var n = t.reason || "Empty reason", r = U(n);
      e(r, n);
    }
  });
}
function oo(e) {
  var t = O({
    version: "5.4.0",
    // This API method is intentionally not monitored, since the only thing executed is the
    // user-provided 'callback'.  All SDK usages executed in the callback should be monitored, and
    // we don't want to interfere with the user uncaught exceptions.
    onReady: function(n) {
      n();
    }
  }, e);
  return Object.defineProperty(t, "_setDebug", {
    get: function() {
      return Pn;
    },
    enumerable: !1
  }), t;
}
function io(e, t, n) {
  var r = e[t];
  e[t] = n, r && r.q && r.q.forEach(function(o) {
    return kt(o, "onReady callback threw an error:")();
  });
}
var S;
(function(e) {
  e.BEFORE_UNLOAD = "beforeunload", e.CLICK = "click", e.DBL_CLICK = "dblclick", e.KEY_DOWN = "keydown", e.LOAD = "load", e.POP_STATE = "popstate", e.SCROLL = "scroll", e.TOUCH_START = "touchstart", e.TOUCH_END = "touchend", e.TOUCH_MOVE = "touchmove", e.VISIBILITY_CHANGE = "visibilitychange", e.PAGE_SHOW = "pageshow", e.FREEZE = "freeze", e.RESUME = "resume", e.DOM_CONTENT_LOADED = "DOMContentLoaded", e.POINTER_DOWN = "pointerdown", e.POINTER_UP = "pointerup", e.POINTER_CANCEL = "pointercancel", e.HASH_CHANGE = "hashchange", e.PAGE_HIDE = "pagehide", e.MOUSE_DOWN = "mousedown", e.MOUSE_UP = "mouseup", e.MOUSE_MOVE = "mousemove", e.FOCUS = "focus", e.BLUR = "blur", e.CONTEXT_MENU = "contextmenu", e.RESIZE = "resize", e.CHANGE = "change", e.INPUT = "input", e.PLAY = "play", e.PAUSE = "pause", e.SECURITY_POLICY_VIOLATION = "securitypolicyviolation", e.SELECTION_CHANGE = "selectionchange", e.STORAGE = "storage";
})(S || (S = {}));
function ae(e, t, n, r, o) {
  return st(e, t, [n], r, o);
}
function st(e, t, n, r, o) {
  var i = o === void 0 ? {} : o, a = i.once, s = i.capture, c = i.passive, u = b(function(l) {
    !l.isTrusted && !l.__ddIsTrusted && !e.allowUntrustedEvents || (a && h(), r(l));
  }), f = c ? { capture: s, passive: c } : s, d = te(t, "addEventListener");
  n.forEach(function(l) {
    return d.call(t, l, u, f);
  });
  function h() {
    var l = te(t, "removeEventListener");
    n.forEach(function(v) {
      return l.call(t, v, u, f);
    });
  }
  return {
    stop: h
  };
}
var N = {
  intervention: "intervention",
  deprecation: "deprecation",
  cspViolation: "csp_violation"
};
function ao(e, t) {
  var n = [];
  oe(t, N.cspViolation) && n.push(uo(e));
  var r = t.filter(function(o) {
    return o !== N.cspViolation;
  });
  return r.length && n.push(so(r)), jt.apply(void 0, n);
}
function so(e) {
  var t = new C(function() {
    if (window.ReportingObserver) {
      var n = b(function(o, i) {
        return o.forEach(function(a) {
          t.notify(co(a));
        });
      }), r = new window.ReportingObserver(n, {
        types: e,
        buffered: !0
      });
      return r.observe(), function() {
        r.disconnect();
      };
    }
  });
  return t;
}
function uo(e) {
  var t = new C(function() {
    var n = ae(e, document, S.SECURITY_POLICY_VIOLATION, function(r) {
      t.notify(fo(r));
    }).stop;
    return n;
  });
  return t;
}
function co(e) {
  var t = e.type, n = e.body;
  return {
    type: t,
    subtype: n.id,
    message: "".concat(t, ": ").concat(n.message),
    stack: rn(n.id, n.message, n.sourceFile, n.lineNumber, n.columnNumber)
  };
}
function fo(e) {
  var t = N.cspViolation, n = "'".concat(e.blockedURI, "' blocked by '").concat(e.effectiveDirective, "' directive");
  return {
    type: N.cspViolation,
    subtype: e.effectiveDirective,
    message: "".concat(t, ": ").concat(n),
    stack: rn(e.effectiveDirective, e.originalPolicy ? "".concat(n, ' of the policy "').concat(Gn(e.originalPolicy, 100, "..."), '"') : "no policy", e.sourceFile, e.lineNumber, e.columnNumber)
  };
}
function rn(e, t, n, r, o) {
  return n ? X({
    name: e,
    message: t,
    stack: [
      {
        func: "?",
        url: n,
        line: r ?? void 0,
        column: o ?? void 0
      }
    ]
  }) : void 0;
}
function on(e, t) {
  var n = window.__ddBrowserSdkExtensionCallback;
  n && n({ type: e, payload: t });
}
function ut(e) {
  return e === null ? "null" : Array.isArray(e) ? "array" : typeof e;
}
function Ie(e, t, n) {
  if (n === void 0 && (n = lo()), t === void 0)
    return e;
  if (typeof t != "object" || t === null)
    return t;
  if (t instanceof Date)
    return new Date(t.getTime());
  if (t instanceof RegExp) {
    var r = t.flags || // old browsers compatibility
    [
      t.global ? "g" : "",
      t.ignoreCase ? "i" : "",
      t.multiline ? "m" : "",
      t.sticky ? "y" : "",
      t.unicode ? "u" : ""
    ].join("");
    return new RegExp(t.source, r);
  }
  if (!n.hasAlreadyBeenSeen(t)) {
    if (Array.isArray(t)) {
      for (var o = Array.isArray(e) ? e : [], i = 0; i < t.length; ++i)
        o[i] = Ie(o[i], t[i], n);
      return o;
    }
    var a = ut(e) === "object" ? e : {};
    for (var s in t)
      Object.prototype.hasOwnProperty.call(t, s) && (a[s] = Ie(a[s], t[s], n));
    return a;
  }
}
function Ze(e) {
  return Ie(void 0, e);
}
function q() {
  for (var e = [], t = 0; t < arguments.length; t++)
    e[t] = arguments[t];
  for (var n, r = 0, o = e; r < o.length; r++) {
    var i = o[r];
    i != null && (n = Ie(n, i));
  }
  return n;
}
function lo() {
  if (typeof WeakSet < "u") {
    var e = /* @__PURE__ */ new WeakSet();
    return {
      hasAlreadyBeenSeen: function(n) {
        var r = e.has(n);
        return r || e.add(n), r;
      }
    };
  }
  var t = [];
  return {
    hasAlreadyBeenSeen: function(n) {
      var r = t.indexOf(n) >= 0;
      return r || t.push(n), r;
    }
  };
}
var ct = {
  log: "log",
  configuration: "configuration"
}, vo = [
  "https://www.datadoghq-browser-agent.com",
  "https://www.datad0g-browser-agent.com",
  "https://d3uc069fcn7uxw.cloudfront.net",
  "https://d20xtzwzcl0ceb.cloudfront.net",
  "http://localhost",
  "<anonymous>"
], po = [yr], A = { maxEventsPerPage: 0, sentEventCount: 0, telemetryEnabled: !1, telemetryConfigurationEnabled: !1 }, Ve;
function go(e, t) {
  var n, r = new C();
  A.telemetryEnabled = !oe(po, t.site) && Je(t.telemetrySampleRate), A.telemetryConfigurationEnabled = A.telemetryEnabled && Je(t.telemetryConfigurationSampleRate);
  var o = ho();
  Ve = function(a) {
    if (A.telemetryEnabled) {
      var s = i(e, a, o);
      r.notify(s), on("telemetry", s);
    }
  }, Nn(sn), O(A, {
    maxEventsPerPage: t.maxTelemetryEventsPerPage,
    sentEventCount: 0
  });
  function i(a, s, c) {
    return q({
      type: "telemetry",
      date: B(),
      service: a,
      version: "5.4.0",
      source: "browser",
      _dd: {
        format_version: 2
      },
      telemetry: q(s, {
        runtime_env: c
      }),
      experimental_features: Bt(Sn())
    }, n !== void 0 ? n() : {});
  }
  return {
    setContextProvider: function(a) {
      n = a;
    },
    observable: r,
    enabled: A.telemetryEnabled
  };
}
function ho() {
  return {
    is_local_file: window.location.protocol === "file:",
    is_worker: "WorkerGlobalScope" in self
  };
}
function bo(e) {
  return e.site === mr;
}
function an(e, t) {
  Xe(L.debug, e, t), ft(O({
    type: ct.log,
    message: e,
    status: "debug"
  }, t));
}
function sn(e, t) {
  ft(O({
    type: ct.log,
    status: "error"
  }, yo(e), t));
}
function mo(e) {
  A.telemetryConfigurationEnabled && ft({
    type: ct.configuration,
    configuration: e
  });
}
function ft(e) {
  Ve && A.sentEventCount < A.maxEventsPerPage && (A.sentEventCount += 1, Ve(e));
}
function yo(e) {
  if (e instanceof Error) {
    var t = U(e);
    return {
      error: {
        kind: t.name,
        stack: X(So(t))
      },
      message: t.message
    };
  }
  return {
    error: {
      stack: Qt
    },
    message: "".concat("Uncaught", " ").concat(ie(e))
  };
}
function So(e) {
  return e.stack = e.stack.filter(function(t) {
    return !t.url || vo.some(function(n) {
      return Mt(t.url, n);
    });
  }), e;
}
var we = 1 / 0, Eo = K, _o = (
  /** @class */
  function() {
    function e(t, n) {
      var r = this;
      this.expireDelay = t, this.maxEntries = n, this.entries = [], this.clearOldValuesInterval = nt(function() {
        return r.clearOldValues();
      }, Eo);
    }
    return e.prototype.add = function(t, n) {
      var r = this, o = {
        value: t,
        startTime: n,
        endTime: we,
        remove: function() {
          var i = r.entries.indexOf(o);
          i >= 0 && r.entries.splice(i, 1);
        },
        close: function(i) {
          o.endTime = i;
        }
      };
      return this.maxEntries && this.entries.length >= this.maxEntries && this.entries.pop(), this.entries.unshift(o), o;
    }, e.prototype.find = function(t) {
      t === void 0 && (t = we);
      for (var n = 0, r = this.entries; n < r.length; n++) {
        var o = r[n];
        if (o.startTime <= t) {
          if (t <= o.endTime)
            return o.value;
          break;
        }
      }
    }, e.prototype.closeActive = function(t) {
      var n = this.entries[0];
      n && n.endTime === we && n.close(t);
    }, e.prototype.findAll = function(t, n) {
      t === void 0 && (t = we), n === void 0 && (n = 0);
      var r = wn(t, n);
      return this.entries.filter(function(o) {
        return o.startTime <= r && t <= o.endTime;
      }).map(function(o) {
        return o.value;
      });
    }, e.prototype.reset = function() {
      this.entries = [];
    }, e.prototype.stop = function() {
      Ht(this.clearOldValuesInterval);
    }, e.prototype.clearOldValues = function() {
      for (var t = Te() - this.expireDelay; this.entries.length > 0 && this.entries[this.entries.length - 1].endTime < t; )
        this.entries.pop();
    }, e;
  }()
), Co = K, Oo = zt;
function wo(e, t, n) {
  var r = vr(e.sessionStoreStrategyType, t, n), o = new _o(Oo);
  r.renewObservable.subscribe(function() {
    o.add(i(), Te());
  }), r.expireObservable.subscribe(function() {
    o.closeActive(Te());
  }), r.expandOrRenewSession(), o.add(i(), Cn().relative), Lo(e, function() {
    return r.expandOrRenewSession();
  }), Ro(e, function() {
    return r.expandSession();
  });
  function i() {
    return {
      id: r.getSession().id,
      trackingType: r.getSession()[t]
    };
  }
  return {
    findActiveSession: function(a) {
      return o.find(a);
    },
    renewObservable: r.renewObservable,
    expireObservable: r.expireObservable,
    expire: r.expire
  };
}
function Lo(e, t) {
  st(e, window, [S.CLICK, S.TOUCH_START, S.KEY_DOWN, S.SCROLL], t, { capture: !0, passive: !0 }).stop;
}
function Ro(e, t) {
  var n = function() {
    document.visibilityState === "visible" && t();
  };
  ae(e, document, S.VISIBILITY_CHANGE, n).stop, nt(n, Co);
}
function un(e) {
  return e >= 500;
}
function To(e) {
  try {
    return e.clone();
  } catch {
    return;
  }
}
var xo = 80 * P, Ao = 32, cn = 3 * Pt, Io = K, fn = he;
function ln(e, t, n, r, o) {
  t.transportStatus === 0 && t.queuedPayloads.size() === 0 && t.bandwidthMonitor.canHandle(e) ? vn(e, t, n, {
    onSuccess: function() {
      return pn(0, t, n, r, o);
    },
    onFailure: function() {
      t.queuedPayloads.enqueue(e), dn(t, n, r, o);
    }
  }) : t.queuedPayloads.enqueue(e);
}
function dn(e, t, n, r) {
  e.transportStatus === 2 && be(function() {
    var o = e.queuedPayloads.first();
    vn(o, e, t, {
      onSuccess: function() {
        e.queuedPayloads.dequeue(), e.currentBackoffTime = fn, pn(1, e, t, n, r);
      },
      onFailure: function() {
        e.currentBackoffTime = Math.min(Io, e.currentBackoffTime * 2), dn(e, t, n, r);
      }
    });
  }, e.currentBackoffTime);
}
function vn(e, t, n, r) {
  var o = r.onSuccess, i = r.onFailure;
  t.bandwidthMonitor.add(e), n(e, function(a) {
    t.bandwidthMonitor.remove(e), ko(a) ? (t.transportStatus = t.bandwidthMonitor.ongoingRequestCount > 0 ? 1 : 2, e.retry = {
      count: e.retry ? e.retry.count + 1 : 1,
      lastFailureStatus: a.status
    }, i()) : (t.transportStatus = 0, o());
  });
}
function pn(e, t, n, r, o) {
  e === 0 && t.queuedPayloads.isFull() && !t.queueFullReported && (o({
    message: "Reached max ".concat(r, " events size queued for upload: ").concat(cn / Pt, "MiB"),
    source: R.AGENT,
    startClocks: re()
  }), t.queueFullReported = !0);
  var i = t.queuedPayloads;
  for (t.queuedPayloads = gn(); i.size() > 0; )
    ln(i.dequeue(), t, n, r, o);
}
function ko(e) {
  return e.type !== "opaque" && (e.status === 0 && !navigator.onLine || e.status === 408 || e.status === 429 || un(e.status));
}
function Uo() {
  return {
    transportStatus: 0,
    currentBackoffTime: fn,
    bandwidthMonitor: No(),
    queuedPayloads: gn(),
    queueFullReported: !1
  };
}
function gn() {
  var e = [];
  return {
    bytesCount: 0,
    enqueue: function(t) {
      this.isFull() || (e.push(t), this.bytesCount += t.bytesCount);
    },
    first: function() {
      return e[0];
    },
    dequeue: function() {
      var t = e.shift();
      return t && (this.bytesCount -= t.bytesCount), t;
    },
    size: function() {
      return e.length;
    },
    isFull: function() {
      return this.bytesCount >= cn;
    }
  };
}
function No() {
  return {
    ongoingRequestCount: 0,
    ongoingByteCount: 0,
    canHandle: function(e) {
      return this.ongoingRequestCount === 0 || this.ongoingByteCount + e.bytesCount <= xo && this.ongoingRequestCount < Ao;
    },
    add: function(e) {
      this.ongoingRequestCount += 1, this.ongoingByteCount += e.bytesCount;
    },
    remove: function(e) {
      this.ongoingRequestCount -= 1, this.ongoingByteCount -= e.bytesCount;
    }
  };
}
function Po(e, t, n, r) {
  var o = Uo(), i = function(a, s) {
    return Fo(e, t, n, a, s);
  };
  return {
    send: function(a) {
      ln(a, o, i, t.trackType, r);
    },
    /**
     * Since fetch keepalive behaves like regular fetch on Firefox,
     * keep using sendBeaconStrategy on exit
     */
    sendOnExit: function(a) {
      Bo(e, t, n, a);
    }
  };
}
function Bo(e, t, n, r) {
  var o = !!navigator.sendBeacon && r.bytesCount < n;
  if (o)
    try {
      var i = t.build("beacon", r), a = navigator.sendBeacon(i, r.data);
      if (a)
        return;
    } catch (c) {
      Mo(c);
    }
  var s = t.build("xhr", r);
  Qe(e, s, r.data);
}
var mt = !1;
function Mo(e) {
  mt || (mt = !0, sn(e));
}
function Fo(e, t, n, r, o) {
  var i = Go() && r.bytesCount < n;
  if (i) {
    var a = t.build("fetch", r);
    fetch(a, { method: "POST", body: r.data, keepalive: !0, mode: "cors" }).then(b(function(c) {
      return o == null ? void 0 : o({ status: c.status, type: c.type });
    }), b(function() {
      var c = t.build("xhr", r);
      Qe(e, c, r.data, o);
    }));
  } else {
    var s = t.build("xhr", r);
    Qe(e, s, r.data, o);
  }
}
function Go() {
  try {
    return window.Request && "keepalive" in new Request("http://a");
  } catch {
    return !1;
  }
}
function Qe(e, t, n, r) {
  var o = new XMLHttpRequest();
  o.open("POST", t, !0), n instanceof Blob && o.setRequestHeader("Content-Type", n.type), ae(e, o, "loadend", function() {
    r == null || r({ status: o.status });
  }, {
    // prevent multiple onResponse callbacks
    // if the xhr instance is reused by a third party
    once: !0
  }), o.send(n);
}
function lt() {
  var e = Do();
  if (e)
    return {
      getAllowedWebViewHosts: function() {
        return JSON.parse(e.getAllowedWebViewHosts());
      },
      send: function(t, n) {
        e.send(JSON.stringify({ eventType: t, event: n }));
      }
    };
}
function ke(e) {
  var t;
  e === void 0 && (e = (t = M().location) === null || t === void 0 ? void 0 : t.hostname);
  var n = lt();
  return !!n && n.getAllowedWebViewHosts().some(function(r) {
    return e === r || An(e, ".".concat(r));
  });
}
function Do() {
  return M().DatadogEventBridge;
}
var fe = {
  HIDDEN: "visibility_hidden",
  UNLOADING: "before_unload",
  PAGEHIDE: "page_hide",
  FROZEN: "page_frozen"
};
function Ho(e) {
  var t = new C(function() {
    var n = Ut(ge.PAGEHIDE), r = st(e, window, [S.VISIBILITY_CHANGE, S.FREEZE, S.PAGE_HIDE], function(i) {
      i.type === S.PAGE_HIDE && n ? t.notify({ reason: fe.PAGEHIDE }) : i.type === S.VISIBILITY_CHANGE && document.visibilityState === "hidden" ? t.notify({ reason: fe.HIDDEN }) : i.type === S.FREEZE && t.notify({ reason: fe.FROZEN });
    }, { capture: !0 }).stop, o = me;
    return n || (o = ae(e, window, S.BEFORE_UNLOAD, function() {
      t.notify({ reason: fe.UNLOADING });
    }).stop), function() {
      r(), o();
    };
  });
  return t;
}
function jo(e) {
  return oe(ee(fe), e);
}
var Yo = (
  /** @class */
  function() {
    function e(t, n, r, o) {
      var i = this;
      this.encoder = t, this.request = n, this.flushController = r, this.messageBytesLimit = o, this.upsertBuffer = {}, this.flushSubscription = this.flushController.flushObservable.subscribe(function(a) {
        return i.flush(a);
      });
    }
    return e.prototype.add = function(t) {
      this.addOrUpdate(t);
    }, e.prototype.upsert = function(t, n) {
      this.addOrUpdate(t, n);
    }, e.prototype.stop = function() {
      this.flushSubscription.unsubscribe();
    }, e.prototype.flush = function(t) {
      var n = ee(this.upsertBuffer).join(`
`);
      this.upsertBuffer = {};
      var r = jo(t.reason), o = r ? this.request.sendOnExit : this.request.send;
      if (r && // Note: checking that the encoder is async is not strictly needed, but it's an optimization:
      // if the encoder is async we need to send two requests in some cases (one for encoded data
      // and the other for non-encoded data). But if it's not async, we don't have to worry about
      // it and always send a single request.
      this.encoder.isAsync) {
        var i = this.encoder.finishSync();
        i.outputBytesCount && o(yt(i, t));
        var a = [i.pendingData, n].filter(Boolean).join(`
`);
        a && o({
          data: a,
          bytesCount: Pe(a),
          flushReason: t.reason
        });
      } else
        n && this.encoder.write(this.encoder.isEmpty ? n : `
`.concat(n)), this.encoder.finish(function(s) {
          o(yt(s, t));
        });
    }, e.prototype.addOrUpdate = function(t, n) {
      var r = ie(t), o = this.encoder.estimateEncodedBytesCount(r);
      if (o >= this.messageBytesLimit) {
        E.warn("Discarded a message whose size was bigger than the maximum allowed size ".concat(this.messageBytesLimit, "KB."));
        return;
      }
      this.hasMessageFor(n) && this.remove(n), this.push(r, o, n);
    }, e.prototype.push = function(t, n, r) {
      var o = this;
      this.flushController.notifyBeforeAddMessage(n), r !== void 0 ? (this.upsertBuffer[r] = t, this.flushController.notifyAfterAddMessage()) : this.encoder.write(this.encoder.isEmpty ? t : `
`.concat(t), function(i) {
        o.flushController.notifyAfterAddMessage(i - n);
      });
    }, e.prototype.remove = function(t) {
      var n = this.upsertBuffer[t];
      delete this.upsertBuffer[t];
      var r = this.encoder.estimateEncodedBytesCount(n);
      this.flushController.notifyAfterRemoveMessage(r);
    }, e.prototype.hasMessageFor = function(t) {
      return t !== void 0 && this.upsertBuffer[t] !== void 0;
    }, e;
  }()
);
function yt(e, t) {
  var n;
  return typeof e.output == "string" ? n = e.output : n = new Blob([e.output], {
    // This will set the 'Content-Type: text/plain' header. Reasoning:
    // * The intake rejects the request if there is no content type.
    // * The browser will issue CORS preflight requests if we set it to 'application/json', which
    // could induce higher intake load (and maybe has other impacts).
    // * Also it's not quite JSON, since we are concatenating multiple JSON objects separated by
    // new lines.
    type: "text/plain"
  }), {
    data: n,
    bytesCount: e.outputBytesCount,
    encoding: e.encoding,
    flushReason: t.reason
  };
}
function zo(e) {
  var t = e.messagesLimit, n = e.bytesLimit, r = e.durationLimit, o = e.pageExitObservable, i = e.sessionExpireObservable, a = o.subscribe(function(p) {
    return d(p.reason);
  }), s = i.subscribe(function() {
    return d("session_expire");
  }), c = new C(function() {
    return function() {
      a.unsubscribe(), s.unsubscribe();
    };
  }), u = 0, f = 0;
  function d(p) {
    if (f !== 0) {
      var m = f, _ = u;
      f = 0, u = 0, v(), c.notify({
        reason: p,
        messagesCount: m,
        bytesCount: _
      });
    }
  }
  var h;
  function l() {
    h === void 0 && (h = be(function() {
      d("duration_limit");
    }, r));
  }
  function v() {
    Dt(h), h = void 0;
  }
  return {
    flushObservable: c,
    get messagesCount() {
      return f;
    },
    /**
     * Notifies that a message will be added to a pool of pending messages waiting to be flushed.
     *
     * This function needs to be called synchronously, right before adding the message, so no flush
     * event can happen after `notifyBeforeAddMessage` and before adding the message.
     *
     * @param estimatedMessageBytesCount: an estimation of the message bytes count once it is
     * actually added.
     */
    notifyBeforeAddMessage: function(p) {
      u + p >= n && d("bytes_limit"), f += 1, u += p, l();
    },
    /**
     * Notifies that a message *was* added to a pool of pending messages waiting to be flushed.
     *
     * This function can be called asynchronously after the message was added, but in this case it
     * should not be called if a flush event occurred in between.
     *
     * @param messageBytesCountDiff: the difference between the estimated message bytes count and
     * its actual bytes count once added to the pool.
     */
    notifyAfterAddMessage: function(p) {
      p === void 0 && (p = 0), u += p, f >= t ? d("messages_limit") : u >= n && d("bytes_limit");
    },
    /**
     * Notifies that a message was removed from a pool of pending messages waiting to be flushed.
     *
     * This function needs to be called synchronously, right after removing the message, so no flush
     * event can happen after removing the message and before `notifyAfterRemoveMessage`.
     *
     * @param messageBytesCount: the message bytes count that was added to the pool. Should
     * correspond to the sum of bytes counts passed to `notifyBeforeAddMessage` and
     * `notifyAfterAddMessage`.
     */
    notifyAfterRemoveMessage: function(p) {
      u -= p, f -= 1, f === 0 && v();
    }
  };
}
function hn(e, t, n, r, o, i) {
  var a = c(e, t), s = n && c(e, n);
  function c(u, f) {
    var d = f.endpoint, h = f.encoder;
    return new Yo(h, Po(u, d, u.batchBytesLimit, r), zo({
      messagesLimit: u.batchMessagesLimit,
      bytesLimit: u.batchBytesLimit,
      durationLimit: u.flushTimeout,
      pageExitObservable: o,
      sessionExpireObservable: i
    }), u.messageBytesLimit);
  }
  return {
    flushObservable: a.flushController.flushObservable,
    add: function(u, f) {
      f === void 0 && (f = !0), a.add(u), s && f && s.add(n.transformMessage ? n.transformMessage(u) : u);
    },
    upsert: function(u, f) {
      a.upsert(u, f), s && s.upsert(n.transformMessage ? n.transformMessage(u) : u, f);
    },
    stop: function() {
      a.stop(), s == null || s.stop();
    }
  };
}
function Ue() {
  var e = "", t = 0;
  return {
    isAsync: !1,
    get isEmpty() {
      return !e;
    },
    write: function(n, r) {
      var o = Pe(n);
      t += o, e += n, r && r(o);
    },
    finish: function(n) {
      n(this.finishSync());
    },
    finishSync: function() {
      var n = {
        output: e,
        outputBytesCount: t,
        rawBytesCount: t,
        pendingData: ""
      };
      return e = "", t = 0, n;
    },
    estimateEncodedBytesCount: function(n) {
      return n.length;
    }
  };
}
function qo(e) {
  var t = /* @__PURE__ */ new Set();
  return e.forEach(function(n) {
    return t.add(n);
  }), Bt(t);
}
var Ko = (
  /** @class */
  function() {
    function e() {
      this.callbacks = {};
    }
    return e.prototype.notify = function(t, n) {
      var r = this.callbacks[t];
      r && r.forEach(function(o) {
        return o(n);
      });
    }, e.prototype.subscribe = function(t, n) {
      var r = this;
      return this.callbacks[t] || (this.callbacks[t] = []), this.callbacks[t].push(n), {
        unsubscribe: function() {
          r.callbacks[t] = r.callbacks[t].filter(function(o) {
            return n !== o;
          });
        }
      };
    }, e;
  }()
);
function Wo(e, t, n) {
  var r = 0, o = !1;
  return {
    isLimitReached: function() {
      if (r === 0 && be(function() {
        r = 0;
      }, K), r += 1, r <= t || o)
        return o = !1, !1;
      if (r === t + 1) {
        o = !0;
        try {
          n({
            message: "Reached max number of ".concat(e, "s by minute: ").concat(t),
            source: R.AGENT,
            startClocks: re()
          });
        } finally {
          o = !1;
        }
      }
      return !0;
    }
  };
}
var qe, dt = /* @__PURE__ */ new WeakMap();
function Jo(e) {
  return qe || (qe = Xo(e)), qe;
}
function Xo(e) {
  var t = new C(function() {
    var n = Q(XMLHttpRequest.prototype, "open", {
      before: $o
    }).stop, r = Q(XMLHttpRequest.prototype, "send", {
      before: function() {
        Zo.call(this, e, t);
      }
    }).stop, o = Q(XMLHttpRequest.prototype, "abort", {
      before: Vo
    }).stop;
    return function() {
      n(), r(), o();
    };
  });
  return t;
}
function $o(e, t) {
  dt.set(this, {
    state: "open",
    method: e.toUpperCase(),
    url: at(String(t))
  });
}
function Zo(e, t) {
  var n = this, r = dt.get(this);
  if (r) {
    var o = r;
    o.state = "start", o.startClocks = re(), o.isAborted = !1, o.xhr = this;
    var i = !1, a = Q(this, "onreadystatechange", {
      before: function() {
        this.readyState === XMLHttpRequest.DONE && s();
      }
    }).stop, s = function() {
      if (c(), a(), !i) {
        i = !0;
        var u = r;
        u.state = "complete", u.duration = On(o.startClocks.timeStamp, B()), u.status = n.status, t.notify(In(u));
      }
    }, c = ae(e, this, "loadend", s).stop;
    t.notify(o);
  }
}
function Vo() {
  var e = dt.get(this);
  e && (e.isAborted = !0);
}
var Ke;
function Qo() {
  return Ke || (Ke = ei()), Ke;
}
function ei() {
  var e = new C(function() {
    if (window.fetch) {
      var t = Zt(window, "fetch", function(n) {
        return function(r, o) {
          var i, a = z(ti, null, [e, r, o]);
          return a ? (i = n.call(this, a.input, a.init), z(ni, null, [e, i, a])) : i = n.call(this, r, o), i;
        };
      }).stop;
      return t;
    }
  });
  return e;
}
function ti(e, t, n) {
  var r = n && n.method || t instanceof Request && t.method, o = r ? r.toUpperCase() : "GET", i = t instanceof Request ? t.url : at(String(t)), a = re(), s = {
    state: "start",
    init: n,
    input: t,
    method: o,
    startClocks: a,
    url: i
  };
  return e.notify(s), s;
}
function ni(e, t, n) {
  var r = function(o) {
    var i = n;
    i.state = "resolve", "stack" in o || o instanceof Error ? (i.status = 0, i.isAborted = o instanceof DOMException && o.code === DOMException.ABORT_ERR, i.error = o) : "status" in o && (i.response = o, i.responseType = o.type, i.status = o.status, i.isAborted = !1), e.notify(i);
  };
  t.then(b(r), b(r));
}
var We = {};
function ri(e) {
  var t = e.map(function(n) {
    return We[n] || (We[n] = oi(n)), We[n];
  });
  return jt.apply(void 0, t);
}
function oi(e) {
  var t = new C(function() {
    var n = I[e];
    return I[e] = function() {
      for (var r = [], o = 0; o < arguments.length; o++)
        r[o] = arguments[o];
      n.apply(console, r);
      var i = Qr();
      z(function() {
        t.notify(ii(r, e, i));
      });
    }, function() {
      I[e] = n;
    };
  });
  return t;
}
function ii(e, t, n) {
  var r = e.map(function(s) {
    return ai(s);
  }).join(" "), o, i;
  if (t === L.error) {
    var a = Tn(e, function(s) {
      return s instanceof Error;
    });
    o = a ? X(U(a)) : void 0, i = tn(a);
  }
  return {
    api: t,
    message: r,
    stack: o,
    handlingStack: n,
    fingerprint: i
  };
}
function ai(e) {
  return typeof e == "string" ? k(e) : e instanceof Error ? nn(U(e)) : ie(k(e), void 0, 2);
}
var si = 500, ui = (
  /** @class */
  function() {
    function e() {
      this.buffer = [];
    }
    return e.prototype.add = function(t) {
      var n = this.buffer.push(t);
      n > si && this.buffer.splice(0, 1);
    }, e.prototype.drain = function() {
      this.buffer.forEach(function(t) {
        return t();
      }), this.buffer.length = 0;
    }, e;
  }()
), $, St = 3 * P, ci = ($ = {}, $[
  0
  /* CustomerDataType.FeatureFlag */
] = "feature flag evaluation", $[
  1
  /* CustomerDataType.User */
] = "user", $[
  2
  /* CustomerDataType.GlobalContext */
] = "global context", $[
  3
  /* CustomerDataType.LoggerContext */
] = "logger context", $);
function fi(e, t) {
  return e > St ? (E.warn("The ".concat(ci[t], " data exceeds the recommended ").concat(St / P, "KiB threshold. More details: https://docs.datadoghq.com/real_user_monitoring/browser/troubleshooting/#customer-data-exceeds-the-recommended-3kib-warning")), !0) : !1;
}
var li = 200;
function Ne(e, t) {
  t === void 0 && (t = Pe);
  var n = {}, r, o = !1, i = new C(), a = Yt(function(c) {
    r = t(ie(c)), o || (o = fi(r, e));
  }, li).throttled, s = {
    getBytesCount: function() {
      return r;
    },
    getContext: function() {
      return Ze(n);
    },
    setContext: function(c) {
      ut(c) === "object" ? (n = k(c), a(n)) : s.clearContext(), i.notify();
    },
    setContextProperty: function(c, u) {
      n[c] = k(u), a(n), i.notify();
    },
    removeContextProperty: function(c) {
      delete n[c], a(n), i.notify();
    },
    clearContext: function() {
      n = {}, r = 0, i.notify();
    },
    changeObservable: i
  };
  return s;
}
var di = "_dd_c", vi = [];
function Et(e, t, n, r) {
  r === void 0 && (r = Pe);
  var o = pi(t, n), i = Ne(n, r);
  return a(), vi.push(ae(e, window, S.STORAGE, function(c) {
    var u = c.key;
    o === u && a();
  })), i.changeObservable.subscribe(s), i;
  function a() {
    var c = localStorage.getItem(o), u = c !== null ? JSON.parse(c) : {};
    i.setContext(u);
  }
  function s() {
    localStorage.setItem(o, JSON.stringify(i.getContext()));
  }
}
function pi(e, t) {
  return "".concat(di, "_").concat(e, "_").concat(t);
}
function gi(e, t, n) {
  var r = e.getReader(), o = [], i = 0;
  a();
  function a() {
    r.read().then(b(function(c) {
      if (c.done) {
        s();
        return;
      }
      n.collectStreamBody && o.push(c.value), i += c.value.length, i > n.bytesLimit ? s() : a();
    }), b(function(c) {
      return t(c);
    }));
  }
  function s() {
    r.cancel().catch(
      // we don't care if cancel fails, but we still need to catch the error to avoid reporting it
      // as an unhandled rejection
      me
    );
    var c, u;
    if (n.collectStreamBody) {
      var f;
      if (o.length === 1)
        f = o[0];
      else {
        f = new Uint8Array(i);
        var d = 0;
        o.forEach(function(h) {
          f.set(h, d), d += h.length;
        });
      }
      c = f.slice(0, n.bytesLimit), u = f.length > n.bytesLimit;
    }
    t(void 0, c, u);
  }
}
var hi = "datadog-synthetics-public-id", bi = "datadog-synthetics-result-id", mi = "datadog-synthetics-injects-rum";
function bn() {
  return !!(window._DATADOG_SYNTHETICS_INJECTS_RUM || Y(mi));
}
function yi() {
  var e = window._DATADOG_SYNTHETICS_PUBLIC_ID || Y(hi);
  return typeof e == "string" ? e : void 0;
}
function Si() {
  var e = window._DATADOG_SYNTHETICS_RESULT_ID || Y(bi);
  return typeof e == "string" ? e : void 0;
}
function _t(e) {
  var t = O({}, e), n = ["id", "name", "email"];
  return n.forEach(function(r) {
    r in t && (t[r] = String(t[r]));
  }), t;
}
function Ei(e) {
  var t = ut(e) === "object";
  return t || E.error("Unsupported user:", e), t;
}
var _i = 32 * P;
function Ci(e) {
  var t = xr(e), n = Ct(e.forwardConsoleLogs, ee(L), "Forward Console Logs"), r = Ct(e.forwardReports, ee(N), "Forward Reports");
  if (!(!t || !n || !r))
    return e.forwardErrorsToLogs && !oe(n, L.error) && n.push(L.error), O({
      forwardErrorsToLogs: e.forwardErrorsToLogs !== !1,
      forwardConsoleLogs: n,
      forwardReports: r,
      requestErrorResponseLengthLimit: _i
    }, t);
}
function Ct(e, t, n) {
  if (e === void 0)
    return [];
  if (!(e === "all" || Array.isArray(e) && e.every(function(r) {
    return oe(t, r);
  }))) {
    E.error("".concat(n, ' should be "all" or an array with allowed values "').concat(t.join('", "'), '"'));
    return;
  }
  return e === "all" ? t : qo(e);
}
function Oi(e) {
  var t = Ar(e);
  return O({
    forward_errors_to_logs: e.forwardErrorsToLogs,
    forward_console_logs: e.forwardConsoleLogs,
    forward_reports: e.forwardReports
  }, t);
}
var wi = function(e, t, n, r) {
  var o = arguments.length, i = o < 3 ? t : r === null ? r = Object.getOwnPropertyDescriptor(t, n) : r, a;
  if (typeof Reflect == "object" && typeof Reflect.decorate == "function")
    i = Reflect.decorate(e, t, n, r);
  else
    for (var s = e.length - 1; s >= 0; s--)
      (a = e[s]) && (i = (o < 3 ? a(i) : o > 3 ? a(t, n, i) : a(t, n)) || i);
  return o > 3 && i && Object.defineProperty(t, n, i), i;
}, y = {
  debug: "debug",
  error: "error",
  info: "info",
  warn: "warn"
}, et = {
  console: "console",
  http: "http",
  silent: "silent"
}, Li = Object.keys(y), Ot = (
  /** @class */
  function() {
    function e(t, n, r, o, i) {
      r === void 0 && (r = et.http), o === void 0 && (o = y.debug), i === void 0 && (i = {}), this.handleLogStrategy = t, this.handlerType = r, this.level = o, this.contextManager = Ne(
        3
        /* CustomerDataType.LoggerContext */
      ), this.contextManager.setContext(i), n && this.contextManager.setContextProperty("logger", { name: n });
    }
    return e.prototype.log = function(t, n, r, o) {
      r === void 0 && (r = y.info);
      var i;
      if (o != null) {
        var a = o instanceof Error ? U(o) : void 0, s = en({
          stackTrace: a,
          originalError: o,
          nonErrorPrefix: "Provided",
          source: R.LOGGER,
          handling: "handled",
          startClocks: re()
        });
        i = {
          stack: s.stack,
          kind: s.type,
          message: s.message
        };
      }
      var c = k(n), u = i ? q({ error: i }, c) : c;
      this.handleLogStrategy({
        message: k(t),
        context: u,
        status: r
      }, this);
    }, e.prototype.debug = function(t, n, r) {
      this.log(t, n, y.debug, r);
    }, e.prototype.info = function(t, n, r) {
      this.log(t, n, y.info, r);
    }, e.prototype.warn = function(t, n, r) {
      this.log(t, n, y.warn, r);
    }, e.prototype.error = function(t, n, r) {
      this.log(t, n, y.error, r);
    }, e.prototype.setContext = function(t) {
      this.contextManager.setContext(t);
    }, e.prototype.getContext = function() {
      return this.contextManager.getContext();
    }, e.prototype.setContextProperty = function(t, n) {
      this.contextManager.setContextProperty(t, n);
    }, e.prototype.removeContextProperty = function(t) {
      this.contextManager.removeContextProperty(t);
    }, e.prototype.clearContext = function() {
      this.contextManager.clearContext();
    }, e.prototype.setHandler = function(t) {
      this.handlerType = t;
    }, e.prototype.getHandler = function() {
      return this.handlerType;
    }, e.prototype.setLevel = function(t) {
      this.level = t;
    }, e.prototype.getLevel = function() {
      return this.level;
    }, wi([
      Bn
    ], e.prototype, "log", null), e;
  }()
), wt = "logs";
function Ri(e) {
  var t = !1, n = Ne(
    2
    /* CustomerDataType.GlobalContext */
  ), r = Ne(
    1
    /* CustomerDataType.User */
  ), o = {}, i = function() {
  }, a = new ui(), s = function(l, v, p, m) {
    p === void 0 && (p = Ze(f())), m === void 0 && (m = B()), a.add(function() {
      return s(l, v, p, m);
    });
  }, c = function() {
  }, u = new Ot(function() {
    for (var l = [], v = 0; v < arguments.length; v++)
      l[v] = arguments[v];
    return s.apply(void 0, l);
  });
  function f() {
    return {
      view: {
        referrer: document.referrer,
        url: window.location.href
      },
      context: n.getContext(),
      user: r.getContext()
    };
  }
  return oo({
    logger: u,
    init: b(function(l) {
      var v;
      if (!l) {
        E.error("Missing configuration");
        return;
      }
      if (c = function() {
        return Ze(l);
      }, ke() && (l = d(l)), !!h(l)) {
        var p = Ci(l);
        if (p) {
          if (l.storeContextsAcrossPages) {
            var m = n.getContext();
            n = Et(
              p,
              wt,
              2
              /* CustomerDataType.GlobalContext */
            ), n.setContext(q(n.getContext(), m));
            var _ = r.getContext();
            r = Et(
              p,
              wt,
              1
              /* CustomerDataType.User */
            ), r.setContext(q(r.getContext(), _));
          }
          v = e(l, p, f), s = v.handleLog, i = v.getInternalContext, a.drain(), t = !0;
        }
      }
    }),
    getGlobalContext: b(function() {
      return n.getContext();
    }),
    setGlobalContext: b(function(l) {
      return n.setContext(l);
    }),
    setGlobalContextProperty: b(function(l, v) {
      return n.setContextProperty(l, v);
    }),
    removeGlobalContextProperty: b(function(l) {
      return n.removeContextProperty(l);
    }),
    clearGlobalContext: b(function() {
      return n.clearContext();
    }),
    createLogger: b(function(l, v) {
      return v === void 0 && (v = {}), o[l] = new Ot(function() {
        for (var p = [], m = 0; m < arguments.length; m++)
          p[m] = arguments[m];
        return s.apply(void 0, p);
      }, k(l), v.handler, v.level, k(v.context)), o[l];
    }),
    getLogger: b(function(l) {
      return o[l];
    }),
    getInitConfiguration: b(function() {
      return c();
    }),
    getInternalContext: b(function(l) {
      return i(l);
    }),
    setUser: b(function(l) {
      Ei(l) && r.setContext(_t(l));
    }),
    getUser: b(function() {
      return r.getContext();
    }),
    setUserProperty: b(function(l, v) {
      var p, m = _t((p = {}, p[l] = v, p))[l];
      r.setContextProperty(l, m);
    }),
    removeUserProperty: b(function(l) {
      return r.removeContextProperty(l);
    }),
    clearUser: b(function() {
      return r.clearContext();
    })
  });
  function d(l) {
    return O({}, l, { clientToken: "empty" });
  }
  function h(l) {
    return t ? (l.silentMultipleInit || E.error("DD_LOGS is already initialized."), !1) : !0;
  }
}
var Ti = "logs";
function xi(e) {
  var t = wo(e, Ti, function(n) {
    return Ii(e, n);
  });
  return {
    findTrackedSession: function(n) {
      var r = t.findActiveSession(n);
      return r && r.trackingType === "1" ? {
        id: r.id
      } : void 0;
    },
    expireObservable: t.expireObservable
  };
}
function Ai(e) {
  var t = mn(e) === "1", n = t ? {} : void 0;
  return {
    findTrackedSession: function() {
      return n;
    },
    expireObservable: new C()
  };
}
function mn(e) {
  return Je(e.sessionSampleRate) ? "1" : "0";
}
function Ii(e, t) {
  var n = ki(t) ? t : mn(e);
  return {
    trackingType: n,
    isTracked: n === "1"
  };
}
function ki(e) {
  return e === "0" || e === "1";
}
function Ui(e, t, n, r, o) {
  var i = Li.concat(["custom"]), a = {};
  i.forEach(function(s) {
    a[s] = Wo(s, t.eventRateLimiterThreshold, o);
  }), n.subscribe(0, function(s) {
    var c, u, f = s.rawLogsEvent, d = s.messageContext, h = d === void 0 ? void 0 : d, l = s.savedCommonContext, v = l === void 0 ? void 0 : l, p = Ln(f.date), m = e.findTrackedSession(p);
    if (m) {
      var _ = v || r(), T = q({
        service: t.service,
        session_id: m.id,
        // Insert user first to allow overrides from global context
        usr: Ft(_.user) ? void 0 : _.user,
        view: _.view
      }, _.context, Re(p), f, h);
      ((c = t.beforeSend) === null || c === void 0 ? void 0 : c.call(t, T)) === !1 || T.origin !== R.AGENT && ((u = a[T.status]) !== null && u !== void 0 ? u : a.custom).isLimitReached() || n.notify(1, T);
    }
  });
}
var Lt = !1;
function Re(e) {
  var t = window;
  if (bn()) {
    var n = r(t.DD_RUM_SYNTHETICS);
    return !n && !Lt && (Lt = !0, an("Logs sent before RUM is injected by the synthetics worker", {
      testId: yi(),
      resultId: Si()
    })), n;
  }
  return r(t.DD_RUM);
  function r(o) {
    if (o && o.getInternalContext)
      return o.getInternalContext(e);
  }
}
var G, Ni = (G = {}, G[L.log] = y.info, G[L.debug] = y.debug, G[L.info] = y.info, G[L.warn] = y.warn, G[L.error] = y.error, G);
function Pi(e, t) {
  var n = ri(e.forwardConsoleLogs).subscribe(function(r) {
    t.notify(0, {
      rawLogsEvent: {
        date: B(),
        message: r.message,
        origin: R.CONSOLE,
        error: r.api === L.error ? {
          stack: r.stack,
          fingerprint: r.fingerprint
        } : void 0,
        status: Ni[r.api]
      }
    });
  });
  return {
    stop: function() {
      n.unsubscribe();
    }
  };
}
var ce, Bi = (ce = {}, ce[N.cspViolation] = y.error, ce[N.intervention] = y.error, ce[N.deprecation] = y.warn, ce);
function Mi(e, t) {
  var n = ao(e, e.forwardReports).subscribe(function(r) {
    var o = r.message, i = Bi[r.type], a;
    i === y.error ? a = {
      kind: r.subtype,
      stack: r.stack
    } : r.stack && (o += " Found in ".concat(Vr(r.stack))), t.notify(0, {
      rawLogsEvent: {
        date: B(),
        message: o,
        origin: R.REPORT,
        error: a,
        status: i
      }
    });
  });
  return {
    stop: function() {
      n.unsubscribe();
    }
  };
}
function Fi(e, t) {
  if (!e.forwardErrorsToLogs)
    return { stop: me };
  var n = Jo(e).subscribe(function(i) {
    i.state === "complete" && o("xhr", i);
  }), r = Qo().subscribe(function(i) {
    i.state === "resolve" && o("fetch", i);
  });
  function o(i, a) {
    !e.isIntakeUrl(a.url) && (ji(a) || un(a.status)) && ("xhr" in a ? Gi(a.xhr, e, s) : a.response ? Hi(a.response, e, s) : a.error && Di(a.error, e, s));
    function s(c) {
      t.notify(0, {
        rawLogsEvent: {
          message: "".concat(Yi(i), " error ").concat(a.method, " ").concat(a.url),
          date: a.startClocks.timeStamp,
          error: {
            stack: c || "Failed to load"
          },
          http: {
            method: a.method,
            status_code: a.status,
            url: a.url
          },
          status: y.error,
          origin: R.NETWORK
        }
      });
    }
  }
  return {
    stop: function() {
      n.unsubscribe(), r.unsubscribe();
    }
  };
}
function Gi(e, t, n) {
  typeof e.response == "string" ? n(vt(e.response, t)) : n(e.response);
}
function Di(e, t, n) {
  n(vt(X(U(e)), t));
}
function Hi(e, t, n) {
  var r = To(e);
  !r || !r.body ? n() : window.TextDecoder ? zi(r.body, t.requestErrorResponseLengthLimit, function(o, i) {
    n(o ? "Unable to retrieve response: ".concat(o) : i);
  }) : r.text().then(b(function(o) {
    return n(vt(o, t));
  }), b(function(o) {
    return n("Unable to retrieve response: ".concat(o));
  }));
}
function ji(e) {
  return e.status === 0 && e.responseType !== "opaque";
}
function vt(e, t) {
  return e.length > t.requestErrorResponseLengthLimit ? "".concat(e.substring(0, t.requestErrorResponseLengthLimit), "...") : e;
}
function Yi(e) {
  return e === "xhr" ? "XHR" : "Fetch";
}
function zi(e, t, n) {
  gi(e, function(r, o, i) {
    if (r)
      n(r);
    else {
      var a = new TextDecoder().decode(o);
      i && (a += "..."), n(void 0, a);
    }
  }, {
    bytesLimit: t,
    collectStreamBody: !0
  });
}
function qi(e, t) {
  if (!e.forwardErrorsToLogs)
    return { stop: me };
  var n = new C(), r = to(n).stop, o = n.subscribe(function(i) {
    t.notify(0, {
      rawLogsEvent: {
        message: i.message,
        date: i.startClocks.timeStamp,
        error: {
          kind: i.type,
          stack: i.stack
        },
        origin: R.SOURCE,
        status: y.error
      }
    });
  });
  return {
    stop: function() {
      r(), o.unsubscribe();
    }
  };
}
var Ki = Ko, Z, Rt = (Z = {}, Z[y.debug] = 0, Z[y.info] = 1, Z[y.warn] = 2, Z[y.error] = 3, Z);
function Wi(e) {
  function t(n, r, o, i) {
    var a = q(r.getContext(), n.context);
    Tt(n.status, et.console, r) && Ji(n, a), Tt(n.status, et.http, r) && e.notify(0, {
      rawLogsEvent: {
        date: i || B(),
        message: n.message,
        status: n.status,
        origin: R.LOGGER
      },
      messageContext: a,
      savedCommonContext: o
    });
  }
  return {
    handleLog: t
  };
}
function Tt(e, t, n) {
  var r = n.getHandler(), o = Array.isArray(r) ? r : [r];
  return Rt[e] >= Rt[n.getLevel()] && oe(o, t);
}
function Ji(e, t) {
  D[e.status].call(I, e.message, t);
}
function Xi(e, t, n, r, o) {
  var i = hn(e, {
    endpoint: e.logsEndpointBuilder,
    encoder: Ue()
  }, e.replica && {
    endpoint: e.replica.logsEndpointBuilder,
    encoder: Ue()
  }, n, r, o);
  return t.subscribe(1, function(a) {
    i.add(a);
  }), i;
}
function $i(e) {
  var t = lt();
  e.subscribe(1, function(n) {
    t.send("log", n);
  });
}
function Zi(e) {
  return {
    get: function(t) {
      var n = e.findTrackedSession(t);
      if (n)
        return {
          session_id: n.id
        };
    }
  };
}
function Vi(e, t, n) {
  var r = new Ki(), o = [];
  r.subscribe(1, function(v) {
    return on("logs", v);
  });
  var i = function(v) {
    r.notify(0, {
      rawLogsEvent: {
        message: v.message,
        date: v.startClocks.timeStamp,
        origin: R.AGENT,
        status: y.error
      }
    }), an("Error reported to customer", { "error.message": v.message });
  }, a = Ho(t), s = t.sessionStoreStrategyType && !ke() && !bn() ? xi(t) : Ai(t), c = Qi(t, i, a, s.expireObservable), u = c.telemetry, f = c.stop;
  o.push(function() {
    return f();
  }), u.setContextProvider(function() {
    var v, p, m, _, T, se;
    return {
      application: {
        id: (v = Re()) === null || v === void 0 ? void 0 : v.application_id
      },
      session: {
        id: (p = s.findTrackedSession()) === null || p === void 0 ? void 0 : p.id
      },
      view: {
        id: (_ = (m = Re()) === null || m === void 0 ? void 0 : m.view) === null || _ === void 0 ? void 0 : _.id
      },
      action: {
        id: (se = (T = Re()) === null || T === void 0 ? void 0 : T.user_action) === null || se === void 0 ? void 0 : se.id
      }
    };
  }), Fi(t, r), qi(t, r), Pi(t, r), Mi(t, r);
  var d = Wi(r).handleLog;
  if (Ui(s, t, r, n, i), ke())
    $i(r);
  else {
    var h = Xi(t, r, i, a, s.expireObservable).stop;
    o.push(function() {
      return h();
    });
  }
  mo(Oi(e));
  var l = Zi(s);
  return {
    handleLog: d,
    getInternalContext: l.get,
    stop: function() {
      o.forEach(function(v) {
        return v();
      });
    }
  };
}
function Qi(e, t, n, r) {
  var o = go("browser-logs-sdk", e), i = [];
  if (ke()) {
    var a = lt(), s = o.observable.subscribe(function(f) {
      return a.send("internal_telemetry", f);
    });
    i.push(function() {
      return s.unsubscribe();
    });
  } else {
    var c = hn(e, {
      endpoint: e.rumEndpointBuilder,
      encoder: Ue()
    }, e.replica && {
      endpoint: e.replica.rumEndpointBuilder,
      encoder: Ue()
    }, t, n, r);
    i.push(function() {
      return c.stop();
    });
    var u = o.observable.subscribe(function(f) {
      return c.add(f, bo(e));
    });
    i.push(function() {
      return u.unsubscribe();
    });
  }
  return {
    telemetry: o,
    stop: function() {
      i.forEach(function(f) {
        return f();
      });
    }
  };
}
var H = Ri(Vi);
io(M(), "DD_LOGS", H);
function x() {
  return (new Error("stack").stack ?? "").split(/\r?\n/).slice(1).flatMap((t) => /\/packages\/logger/.test(t) ? [] : [t.trim().slice(3)]).join(`
`);
}
var xt, At, It;
const j = ((At = (xt = import.meta) == null ? void 0 : xt.env) == null ? void 0 : At.VITE_DATADOG_CLIENT_TOKEN) ?? (typeof process < "u" ? (It = process.env) == null ? void 0 : It.NEXT_DATADOG_CLIENT_TOKEN : "");
j && H.init({
  clientToken: j,
  service: "checkout-form",
  env: typeof process < "u" ? process.env.NODE_ENV : "prod",
  forwardConsoleLogs: "all",
  forwardReports: "all",
  site: "datadoghq.eu	",
  forwardErrorsToLogs: !0,
  sessionSampleRate: 100
});
let w = {};
const ea = {
  addDefaultAttributes: (e) => {
    w = { ...w, ...e };
  },
  info: (e, t) => {
    if (j) {
      H.logger.info(e, { ...t, ...w, stack: x() });
      return;
    }
    console.info(e, { ...t, ...w, stack: x() });
  },
  debug: (e, t) => {
    if (j) {
      H.logger.debug(e, { ...t, ...w, stack: x() });
      return;
    }
    console.debug(e, { ...t, ...w, stack: x() });
  },
  trace: (e, t) => {
    if (j) {
      H.logger.debug(e, { ...t, ...w, stack: x() });
      return;
    }
    console.trace(e, { ...t, ...w, stack: x() });
  },
  warn: (e, t) => {
    if (j) {
      H.logger.warn(e, { ...t, ...w, stack: x() });
      return;
    }
    console.warn(e, { ...t, ...w, stack: x() });
  },
  error: (e, t) => {
    if (j) {
      H.logger.error(e, { ...t, ...w, stack: x() });
      return;
    }
    console.error(e, { ...t, ...w, stack: x() });
  }
};
export {
  ea as logger
};
